import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { catchError, concat, map, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { MediaIdModel } from 'src/models/mediaIdModel';
import { UiService } from 'src/services/ui.service';
import { MediaModel } from 'src/models/mediaModel';
import { AdminMediaApiService } from 'src/services/api/admin-media.api.service';
import { AdminProductCategoriesApiService } from 'src/services/api/admin-product-categories.api.service';
import { AdminProductCategoriesEditRequestModel } from 'src/models/adminProductCategoriesEditRequestModel';
import { AdminProductCategoryModel } from 'src/models/adminProductCategoryModel';

@Component({
  selector: 'linde-control-categories-item',
  templateUrl: './control-categories-item.component.html',
  styleUrls: ['./control-categories-item.component.scss'],
  animations: [titleAnimation]
})
export class ControlCategoriesItemComponent {
  title = 'Категории товаров [TODO API] / ';
  form: UntypedFormGroup;
  StateValue = StateValue;
  images: MediaModel[] = [];


  private _getProductCategoryApi$ =
    (productCategoryId: string): Observable<ComponentState<AdminProductCategoryModel>> =>
      this.adminProductCategoriesApi.get({ productCategoryId })
        .pipe(
          map(item => ({ state: StateValue.Success, data: item } as ComponentState<AdminProductCategoryModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
          tap((currentState: ComponentState<AdminProductCategoryModel>) => {
            if (currentState.state === StateValue.Success)
              this.images = currentState.data?.media ?? [];
          }),
        );

  private _updateProductCategoryApi$ =
    (model: AdminProductCategoriesEditRequestModel): Observable<ComponentState> =>
      this.adminProductCategoriesApi.edit(model)
        .pipe(
          map(() => ({ state: StateValue.Success, msg: 'Продукт успешно обновлен' } as ComponentState)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
        );

  private _submit$ = new Subject<AdminProductCategoriesEditRequestModel>();

  constructor(
    private ui: UiService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private adminProductCategoriesApi: AdminProductCategoriesApiService,
    public fileApi: AdminMediaApiService,
  ) {
    this.ui.setTitle(this.title + '...');
  }

  productState$: Observable<ComponentState<AdminProductCategoryModel>> = this.activatedRoute.params
    .pipe(
      switchMap(
        params => concat(
          of({ state: StateValue.InProgress }),
          this._getProductCategoryApi$(params['id'])
            .pipe(tap(state => {
              if (state.state = StateValue.Success) {
                this.ui.setTitle(this.title + state.data.name);
                this.titleService.setTitle(this.title + state.data.name);
                this.generateForm(state.data);
              }
            })),
        )
      ),
      shareReplay(1),
    );

  updateProductState$: Observable<ComponentState> = concat(
    of({ state: StateValue.Initial }),
    this._submit$.pipe(
      switchMap(model => concat(
        of({ state: StateValue.InProgress }),
        this._updateProductCategoryApi$(model),
      ))
    )
  )
    .pipe(shareReplay(1));

  onImagesLoaded(model: MediaModel) {
    this.form.patchValue({ mediaAddIds: [{ mediaResourceId: model.mediaId }] });
    this.images.push(model);
  }

  removeImage(mediaId: string) {
    this.images = this.images.filter(i => i.mediaId !== mediaId);
    this.form.patchValue({ mediaDeleteIds: [{ mediaId }] });
  }

  private generateForm(item: AdminProductCategoryModel) {
    this.form = new UntypedFormGroup({
      productCategoryId: new UntypedFormControl(item.productCategoryId, [Validators.required]),
      // name: new FormControl(item.name, [Validators.required]),
      detailText: new UntypedFormControl(item.detailText),
      mediaAddIds: new UntypedFormControl([]),
      mediaDeleteIds: new UntypedFormControl([]),
    });
  }

  addMedia(mediaId: string) {
    const mediaAddIds: MediaIdModel[] = this.form.get('mediaAddIds').value as MediaIdModel[];
    mediaAddIds.push({ mediaId });
    this.form.patchValue({ mediaAddIds });
  }

  deleteMedia(mediaId: string) {
    let mediaDeleteIds: MediaIdModel[] = this.form.get('mediaDeleteIds').value as MediaIdModel[];
    mediaDeleteIds = mediaDeleteIds.filter(m => m.mediaId !== mediaId);
    this.form.patchValue({ mediaDeleteIds });
  }

  submit() {
    this._submit$.next(this.form.value);
    // this.adminProductsApi.edit({})
  }
}
