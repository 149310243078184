<form *ngIf="form && formState" [formGroup]="form" class="col" (ngSubmit)="submit()">

    <div *ngIf="pageCode === ContentPageCode.News" class="row dates">
        <i-form-item [control]="form.get('dateFrom')">
            <label> С </label>
            <input type="date" formControlName="dateFrom">
        </i-form-item>

        <i-form-item [control]="form.get('dateTo')">
            <label> По </label>
            <input type="date" formControlName="dateTo">
        </i-form-item>
    </div>

    <i-form-item [control]="form.get('subject')">
        <label> Заголовок </label>
        <input type="text" formControlName="subject">
    </i-form-item>

    <i-form-item [control]="form.get('detailText')">
        <label> Подробное описание </label>
        <div class="col detail-text">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
            <ngx-editor [editor]="editor" placeholder="" formControlName="detailText"></ngx-editor>
        </div>
    </i-form-item>

    <i-form-item [control]="form.get('url')">
        <label> URL </label>
        <input type="text" formControlName="url">
    </i-form-item>

    <i-form-item *ngIf="pageCode !== ContentPageCode.News" [control]="form.get('displaySeq')">
        <label> Порядок </label>
        <input type="number" formControlName="displaySeq">
    </i-form-item>

    <i-form-item [control]="form.get('isActive')">
        <div class="chb row">
            <input type="checkbox" formControlName="isActive" id="chb_is-active">
            <label for="chb_is-active"> Активно </label>
        </div>
    </i-form-item>

    <div class="images row">
        <div *ngFor="let image of images" class="images-item col">
            <img src="/assets/icons/close.svg" alt="CLOSE" class="icon" (click)="removeImage(image.mediaId)">
            <img [src]="image.photo640 ?? image.photoOriginal |fakeImg" alt="GAS" class="image">
        </div>
        <file-input *ngIf="!images.length" [fileApi]="fileApi" (onImagesLoaded)="onImagesLoaded($event)">
            <div class="images-item add">
                <img src="/assets/icons/add-circle-outline.svg" alt="ADD">
            </div>
        </file-input>
    </div>
    
    <app-state-message [state]="formState"></app-state-message>

    <button type="submit" [disabled]="form.invalid || formState.state === StateValue.InProgress">
        <ng-container *ngIf="formState.state !== StateValue.InProgress; else inProgress">
            Сохранить
        </ng-container>
        <ng-template #inProgress>
            <i-in-progress *ngIf="formState.state === StateValue.InProgress" size="24px"> </i-in-progress>
        </ng-template>
    </button>

</form>