export interface ComponentState<T = void> {
    state: StateValue;
    data?: T;
    msg?: string;
}

export enum StateValue {
    Initial,
    InProgress,
    Success,
    Error,
}