import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminOrderModel } from 'src/models/adminOrderModel';
import { CustomerOrderModel } from 'src/models/customerOrderModel';

@Component({
  selector: 'linde-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderComponent {
  @Input() item: AdminOrderModel;
  @Input() expanded: boolean;

  @Output() onToggle = new EventEmitter<string>();

  constructor() { }

  toggle() { 
    this.onToggle.emit(this.item.orderId); 
  }

}
