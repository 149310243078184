import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminNotificationIdModel } from 'src/models/adminNotificationIdModel';
import { AdminNotificationModel } from 'src/models/adminNotificationModel';
import { AdminNotificationsListRequestModel } from 'src/models/adminNotificationsListRequestModel';
import { AdminNotificationsListResponseModel } from 'src/models/adminNotificationsListResponseModel';


@Injectable({
  providedIn: 'root'
})
export class AdminNotificationsApiService {
  route = '/admin/notifications';
  constructor(private http: HttpClient) { }

  list(model: AdminNotificationsListRequestModel) {
    return this.http.post<AdminNotificationsListResponseModel>(`${environment.baseUrl}${this.route}/list`, model)
      .pipe(
        map(list => {
          list.items = list.items.map(model => this._mapToModel(model) as AdminNotificationModel);
          return list;
        })
      );
  }

  get(model: AdminNotificationIdModel) {
    return this.http.post<AdminNotificationModel>(`${environment.baseUrl}${this.route}/get`, model)
      .pipe(
        map(model => this._mapToModel(model))
      );
  }

  create(model: AdminNotificationModel) {
    return this.http.post<AdminNotificationModel>(`${environment.baseUrl}${this.route}/create`, model)
  }

  edit(model: AdminNotificationModel) {
    return this.http.post<AdminNotificationModel>(`${environment.baseUrl}${this.route}/edit`, model)
  }

  // private _mapToDTO(model: AdminContentPageEditRequestModel | AdminContentPageModel) {
  //   model.detailText = model.detailText
  //     ? toHTML(model.detailText as any)
  //     : '';
  //   return model;
  // }

  private _mapToModel(model: AdminNotificationModel) {
    model.dateFrom = this._convertDatetimeToDate(model.dateFrom);
    model.dateTo = this._convertDatetimeToDate(model.dateTo);
    return model;
  }

  private _convertDatetimeToDate(datetime: string): string {
    datetime = datetime?.trim();
    if (datetime)
      return datetime.substring(0, 10);
    return null;
  }
}
