export const Roles: { [id: string]: string } = {
    Admin: 'Администратор',
    User: 'Пользователь',
    Manager: 'Менеджер',
    Content: 'Контент-менеджер',
};

export interface Role {
    id: string;
    name: string;
}

export const RoleList: Role[] = [
    { id: 'Admin', name: 'Администратор', },
    { id: 'User', name: 'Пользователь', },
    { id: 'Manager', name: 'Менеджер', },
    { id: 'Content', name: 'Контент-менеджер', },
];