import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toDoc, toHTML } from 'ngx-editor';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminContentPageEditRequestModel } from 'src/models/adminContentPageEditRequestModel';
import { AdminContentPageIdModel } from 'src/models/adminContentPageIdModel';
import { AdminContentPageModel } from 'src/models/adminContentPageModel';
import { AdminContentPagesListRequestModel } from 'src/models/adminContentPagesListRequestModel';
import { AdminContentPagesListResponseModel } from 'src/models/adminContentPagesListResponseModel';




@Injectable({
  providedIn: 'root'
})
export class AdminContentApiService {
  route = '/admin/content';

  constructor(private http: HttpClient) { }

  list(model: AdminContentPagesListRequestModel) {
    return this.http.post<AdminContentPagesListResponseModel>(`${environment.baseUrl}${this.route}/list`, model)
      .pipe(
        map(list => {
          list.items = list.items.map(model => this._mapToModel(model) as AdminContentPageModel);
          return list;
        })
      );
  }

  get(model: AdminContentPageIdModel) {
    return this.http.post<AdminContentPageModel>(`${environment.baseUrl}${this.route}/get`, model)
      .pipe(
        map(model => this._mapToModel(model))
      );
  }

  create(model: AdminContentPageEditRequestModel) {
    return this.http.post<AdminContentPageIdModel>(
      `${environment.baseUrl}${this.route}/create`,
      this._mapToDTO(model)
    )
  }

  edit(model: AdminContentPageEditRequestModel) {
    return this.http.post<AdminContentPageIdModel>(
      `${environment.baseUrl}${this.route}/edit`,
      this._mapToDTO(model)
    )
  }

  private _mapToDTO(model: AdminContentPageEditRequestModel | AdminContentPageModel) {
    model.detailText = model.detailText
      ? toHTML(model.detailText as any)
      : '';
    return model;
  }

  private _mapToModel(model: AdminContentPageEditRequestModel | AdminContentPageModel) {
    model.detailText = toDoc(model.detailText as any);
    model.dateFrom = this._convertDatetimeToDate(model.dateFrom);
    model.dateTo = this._convertDatetimeToDate(model.dateTo);
    return model;
  }

  private _convertDatetimeToDate(datetime: string): string {
    datetime = datetime?.trim();
    if (datetime)
      return datetime.substring(0, 10);
    return null;
  }

  private _convertDateToDatetime(date: string): string {
    return `${date}T00:00:00Z`
  }
}
