import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiInterceptor } from 'src/services/api/api.interceptor';
import { SharedModule } from '../shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ControlMenuComponent } from './components/control-menu/control-menu.component';
import { ControlContractsComponent } from './pages/control-contracts/control-contracts.component';
import { ControlDataExchangeLogComponent } from './pages/control-data-exchange-log/control-data-exchange-log.component';
import { ControlDocsComponent } from './pages/control-docs/control-docs.component';
import { ControlGoodsItemComponent } from './pages/control-goods-item/control-goods-item.component';
import { ControlGoodsComponent } from './pages/control-goods/control-goods.component';
import { ControlNotificationsEditComponent } from './pages/control-notifications-edit/control-notifications-edit.component';
import { ControlNotificationsComponent } from './pages/control-notifications/control-notifications.component';
import { ControlOrdersComponent } from './pages/control-orders/control-orders.component';
import { ControlUsersEditComponent } from './pages/control-users-edit/control-users-edit.component';
import { ControlUsersComponent } from './pages/control-users/control-users.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { EnterComponent } from './pages/enter/enter.component';
import { FormItemComponent } from './components/form-item/form-item.component';
import { ControlUsersAddComponent } from './pages/control-users-add/control-users-add.component';
import { OrderComponent } from './components/order/order.component';
import { NotificationFormComponent } from './components/notification-form/notification-form.component';
import { ControlNotificationsAddComponent } from './pages/control-notifications-add/control-notifications-add.component';
import { ControlDataExchangeLogItemComponent } from './pages/control-data-exchange-log-item/control-data-exchange-log-item.component';
import { ControlContentComponent } from './pages/control-content/control-content.component';
import { ControlContentAddComponent } from './pages/control-content-add/control-content-add.component';
import { ControlContentEditComponent } from './pages/control-content-edit/control-content-edit.component';
import { ContentItemChildrenComponent } from './components/content-item-children/content-item-children.component';
import { ContentFormComponent } from './components/content-form/content-form.component';
import { NgxEditorModule } from 'ngx-editor';
import { ControlFeedbackComponent } from './pages/control-feedback/control-feedback.component';
import { ControlFeedbackItemComponent } from './pages/control-feedback-item/control-feedback-item.component';
import { ControlCategoriesComponent } from './pages/control-categories/control-categories.component';
import { ControlCategoriesItemComponent } from './pages/control-categories-item/control-categories-item.component';
import { ControlEmailsEditComponent } from './pages/control-emails-edit/control-emails-edit.component';
import { ControlEmailsComponent } from './pages/control-emails/control-emails.component';
import { EmailsFormComponent } from './components/emails-form/emails-form.component';
import { provideNgxMask } from 'ngx-mask';
import { CabGuard } from 'src/services/cab.guard';

@NgModule({
  declarations: [
    AppComponent,

    ControlGoodsComponent,
    ControlContractsComponent,
    ControlCategoriesComponent,
    ControlCategoriesItemComponent,
    ControlOrdersComponent,
    ControlUsersComponent,
    ControlNotificationsComponent,
    ControlNotificationsEditComponent,
    ControlDocsComponent,
    ControlDataExchangeLogComponent,
    ControlGoodsItemComponent,
    ControlUsersEditComponent,
    EnterComponent,

    ControlMenuComponent,
    ControlUsersAddComponent,
    FormItemComponent,
    FooterComponent,
    HeaderComponent,
    OrderComponent,
    NotificationFormComponent,
    ControlNotificationsAddComponent,
    ControlDataExchangeLogItemComponent,
    ControlContentComponent,
    ControlContentAddComponent,
    ControlContentEditComponent,
    ContentItemChildrenComponent,
    ContentFormComponent,
    ControlFeedbackComponent,
    ControlFeedbackItemComponent,
    ControlEmailsEditComponent,
    ControlEmailsComponent,
    EmailsFormComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxEditorModule
      .forRoot({
        locals: {
          bold: 'Bold',
          italic: 'Italic',
          // code: 'Code',
          // underline: 'Underline',
          // ...
        },
      }),

    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    provideNgxMask(),
    CabGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
