import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminEmailsIdModel } from 'src/models/adminEmailsIdModel';
import { AdminEmailsListRequestModel } from 'src/models/adminEmailsListRequestModel';
import { AdminEmailsListResponseModel } from 'src/models/adminEmailsListResponseModel';
import { AdminEmailsModel } from 'src/models/adminEmailsModel';


@Injectable({
  providedIn: 'root'
})
export class AdminEmailsApiService {
  route = '/admin/emailGroups';
  constructor(private http: HttpClient) { }

  list(model: AdminEmailsListRequestModel) {
    return this.http.post<AdminEmailsListResponseModel>(`${environment.baseUrl}${this.route}/list`, model);
  }

  get(model: AdminEmailsIdModel) {
    return this.http.post<AdminEmailsModel>(`${environment.baseUrl}${this.route}/get`, model);
  }


  edit(model: AdminEmailsModel) {
    return this.http.post<AdminEmailsModel>(`${environment.baseUrl}${this.route}/edit`, model)
  }
}
