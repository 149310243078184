<div class="wrap col" @titleAnimation>
  <div class="filters row">
    <input type="text" role="search" tabindex="0" class="search" placeholder="Поиск" (input)="search($event)">

    <select (change)="orderBy($event)">
      <option *ngFor="let o of Dropdowns.sort" [value]="o.id"> {{o.name}} </option>
    </select>
  </div>

  <div *ngIf="pageState$ |async as pageState" class="list">
    <ng-container [ngSwitch]="pageState.state">
      <ng-container *ngSwitchCase="StateValue.Success">

        <a [routerLink]="[item.productId]" *ngFor="let item of pageState.data.items" class="item row">
          <img [src]="item.media?.[0]?.photo640 ?? item.media?.[0]?.photoOriginal |fakeImg" alt="GAS">
          <div class="info row">
            <div class="name">
              <div class="title">{{item.name}}</div>
              <div class="subtitle">{{item.measureUnit}}</div>
            </div>
            <div class="prices">
              <p *ngIf="item.basePrice" class="price">
                {{item.basePrice | number:'1.0-0' | replace:',':' '}} Руб.</p>
              <p *ngIf="item.basePrice" class="price-without-tax">
                {{(item.basePrice - item.vatSum) | number:'1.0-0' | replace:',':' '}} Руб. без НДС
              </p>
            </div>
            <div class="status">
              {{item.isActive ? 'В продаже' : ''}}
            </div>
          </div>
        </a>

        <div *ngIf="pageState.data?.items?.length === 0" class="msg warning">
          Список пуст
        </div>

      </ng-container>

      <app-state-message [state]="pageState" *ngSwitchCase="StateValue.Error"> </app-state-message>
      <div class="center" *ngSwitchCase="StateValue.InProgress">
        <i-in-progress size="40px"></i-in-progress>
      </div>

    </ng-container>

    <linde-pagination [itemsQty]="pageState.data?.count" (onPageChanged)="onPageChanged($event)"></linde-pagination>

  </div>

</div>