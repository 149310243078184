export enum ContentPageCode {
    //Parents
    Pages = 'pages',
    Banners = 'banners',
    Categories = 'categories',
    Benefits = 'benefits',
    News = 'news',
    Faq = 'faq',

    // Pages
    Contacts = 'contacts',
    Documents = 'documents',
    HowOrder = 'how-rder',
    Delivery = 'delivery',
    Privacy = 'privacy',
    Company = 'company',
    TermsOfUse = 'terms-of-use',
    LegalInfo = 'legal-info',
    Payment = 'payment',
}