import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, catchError, combineLatest, concat, debounceTime, distinctUntilChanged, map, Observable, of, share, shareReplay, Subject, switchMap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminExchangeMessagesListResponseModel } from 'src/models/adminExchangeMessagesListResponseModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { PaginationValue } from 'src/modules/shared/components/pagination/pagination.component';
import { AdminExchangeApiService } from 'src/services/api/admin-exchange.api.service';
import { UiService } from 'src/services/ui.service';
import { Dropdowns } from 'src/modules/shared/helpers/dropdowns';

@Component({
  selector: 'linde-control-data-exchange-log',
  templateUrl: './control-data-exchange-log.component.html',
  styleUrls: ['./control-data-exchange-log.component.scss'],
  animations: [titleAnimation]
})
export class ControlDataExchangeLogComponent {
  title = 'Журнал обмена данными';
  Dropdowns = Dropdowns.logList;
  StateValue = StateValue;

  private _ordering$ = new BehaviorSubject<string>(this.Dropdowns.sort[0].id);
  private _pagination$ = new BehaviorSubject<PaginationValue>({ itemsPerPage: 20, page: 1 });
  private _search$ = new BehaviorSubject<string>('');
  private _errorOnly$ = new BehaviorSubject<boolean>(false);

  private _syncRunner$ = new Subject<boolean>();

  private _listApi$ =
    (ordering: string, pagination: PaginationValue, searchValue: string, errorOnly: boolean) =>
      this.adminExchangeApi.listMessages({
        sortType: ordering,
        search: searchValue,
        showOnlyErrors: errorOnly,
        limit: pagination.itemsPerPage,
        offset: (pagination.page - 1) * pagination.itemsPerPage,
      })
        .pipe(
          map(list => ({ state: StateValue.Success, data: list } as ComponentState<AdminExchangeMessagesListResponseModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message }))
        );

  private _syncApi$ =
    (): Observable<ComponentState> =>
      this.adminExchangeApi.runNavisionSync()
        .pipe(
          map(() => ({ state: StateValue.Success, msg: 'Синхронизация запущена' } as ComponentState)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message }))
        );

  constructor(
    private ui: UiService,
    private titleService: Title,
    private adminExchangeApi: AdminExchangeApiService,
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  pageState$: Observable<ComponentState<AdminExchangeMessagesListResponseModel>> = combineLatest([
    this._ordering$,
    this._pagination$,
    this._search$.pipe(
      debounceTime(500),
      map(value => value?.length >= 3 ? value : ''),
      distinctUntilChanged(),
    ),
    this._errorOnly$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ),
  ])
    .pipe(
      switchMap(_combined => concat(
        of({ state: StateValue.InProgress }),
        this._listApi$(..._combined),
      )),
      share(),
    );

  syncState$: Observable<ComponentState> = concat(
    of({ state: StateValue.Initial }),
    this._syncRunner$
      .pipe(
        switchMap(() => concat(
          of({ state: StateValue.InProgress }),
          this._syncApi$(),
        ))
      )
  )
    .pipe(shareReplay(1));

  orderBy(e: Event) {
    this._ordering$.next((e.target as HTMLSelectElement).value);
  }

  search(e: Event) {
    this._search$.next((e.target as HTMLInputElement).value?.trim());
  }

  onPageChanged(pv: PaginationValue) {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    this._pagination$.next(pv);
  }

  toggleErrorOnly(e: Event) {
    this._errorOnly$.next((e.target as HTMLInputElement).checked);
  }

  runSync() {
    this._syncRunner$.next(true);
  }
}
