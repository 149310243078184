<div class="wrap col" @titleAnimation>

    <div class="filters row">
        <input type="text" role="search" tabindex="0" class="search" placeholder="Поиск" (input)="search($event)">

        <select (change)="orderBy($event)">
            <option *ngFor="let o of Dropdowns.sort" [value]="o.id"> {{o.name}} </option>
        </select>
    </div>

    <div *ngIf="pageState$ |async as pageState" class="table">

        <ng-container [ngSwitch]="pageState.state">
            <ng-container *ngSwitchCase="StateValue.Success">

                <div class="table-head">
                    <div class="cell"> Имя клиента </div>
                    <div class="cell"> Сообщенияе </div>
                    <!-- <div class="cell"> Тип </div>
                    <div class="cell"> Файлы </div> -->
                </div>

                <div class="table-body">
                    <a *ngFor="let item of pageState.data.items" [routerLink]="[item.feedbackId]"
                        class="table-body-row">
                        <!-- <div class="cell"> {{item.feedbackId}} </div> -->
                        <div class="cell"> {{item.customerName}} </div>
                        <div class="cell"> {{item.message}} </div>
                        <!-- <div class="cell"> {{item.feedbackTypeName}} </div>
                        <div class="cell">
                            <p *ngFor="let file of item.files">
                                <a [href]="file.url" [download]="file.fileName"> {{file.fileName}} </a>
                            </p>
                        </div> -->
                    </a>
                </div>

                <div *ngIf="!pageState.data.items.length" class="msg warning">
                    Нет данных
                </div>

            </ng-container>

            <div class="center" *ngSwitchCase="StateValue.InProgress">
                <i-in-progress size="40px"></i-in-progress>
            </div>

            <app-state-message [state]="pageState" *ngSwitchCase="StateValue.Error"> </app-state-message>

        </ng-container>

        <linde-pagination [itemsQty]="pageState.data?.count" (onPageChanged)="onPageChanged($event)"></linde-pagination>

    </div>
</div>