import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { AdminContentPageModel } from 'src/models/adminContentPageModel';
import { MediaModel } from 'src/models/mediaModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { ContentPageCode } from 'src/modules/shared/helpers/content-page-codes';
import { AdminMediaApiService } from 'src/services/api/admin-media.api.service';

@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
  styleUrls: ['./content-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFormComponent implements OnInit, OnChanges, OnDestroy {
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic', 'underline', 'strike', 'code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    // ['link', 'image'],
    ['link'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  html = '';
  images: MediaModel[] = [];
  // images2: number[] = [1, 2, 3, 4];

  ContentPageCode = ContentPageCode;
  StateValue = StateValue;

  @Input() item: AdminContentPageModel;
  @Input() formState: ComponentState;
  @Input() fileApi: AdminMediaApiService;
  @Input() pageCode: ContentPageCode;
  @Output() onSubmit = new EventEmitter<AdminContentPageModel>();

  form: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.generateForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('item' in changes || 'pageCode' in changes)
      this.generateForm();

    if ('item' in changes)
      this.images = this.item?.media ?? [];
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  generateForm(): void {
    this.form = new UntypedFormGroup({
      pageId: new UntypedFormControl(this.item?.pageId),
      parentId: new UntypedFormControl(this.item?.parentId),
      parentCode: new UntypedFormControl(this.item?.parentCode ?? this.pageCode),
      code: new UntypedFormControl(this.item?.code),
      dateFrom: new UntypedFormControl(this.item?.dateFrom),
      dateTo: new UntypedFormControl(this.item?.dateTo),
      displaySeq: new UntypedFormControl(this.item?.displaySeq),
      isActive: new UntypedFormControl(this.item?.isActive ?? true),
      subject: new UntypedFormControl(this.item?.subject ?? '', [Validators.required]),
      detailText: new UntypedFormControl(this.item?.detailText),
      url: new UntypedFormControl(this.item?.url),

      mediaAddIds: new UntypedFormControl([]),
      mediaDeleteIds: new UntypedFormControl([]),
    })
  }

  onImagesLoaded(model: MediaModel) {
    this.form.patchValue({ mediaAddIds: [{ mediaResourceId: model.mediaId }] });
    this.images.push(model);
  }

  removeImage(mediaId: string) {
    this.images = this.images.filter(i => i.mediaId !== mediaId);
    this.form.patchValue({ mediaDeleteIds: [{ mediaId }] });
  }

  submit() {
    this.onSubmit.emit(this.form.value);
  }
}
