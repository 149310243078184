//TODO: 
export const Dropdowns = {
    userList: {
        filter: [
            { id: 0, name: 'Все' },
            { id: 1, name: 'Активные' },
        ],
        sort: [
            { id: 'Id DESC', name: 'Сначала новые' },
            { id: 'Id ASC', name: 'Сначала старые' }
        ]
    },
    productList: {
        sort: [
            { id: 'Id DESC', name: 'Сначала новые' },
            { id: 'Id ASC', name: 'Сначала старые' }
        ]
    },
    orderList: {
        filter: [
            { id: false, name: 'Все' },
            { id: true, name: 'Активные' },
        ],
        sort: [
            { id: 'Id DESC', name: 'Сначала новые' },
            { id: 'Id ASC', name: 'Сначала старые' }
        ]
    },
    notificationList: {
        sort: [
            { id: 'DateTo DESC', name: 'Сначала новые' },
            { id: 'DateTo ASC', name: 'Сначала старые' }
        ]
    },
    logList: {
        sort: [
            { id: 'MessageDate DESC', name: 'Сначала новые' },
            { id: 'MessageDate ASC', name: 'Сначала старые' }
        ]
    },
    feedbackList: {
        sort: [
            { id: 'FeedbackDate DESC', name: 'Сначала новые' },
            { id: 'FeedbackDate ASC', name: 'Сначала старые' }
        ]
    },

}