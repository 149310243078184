import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, concat, map, Observable, of, shareReplay, Subject, switchMap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminNotificationModel } from 'src/models/adminNotificationModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { AdminNotificationsApiService } from 'src/services/api/admin-notifications.api.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-control-notifications-add',
  templateUrl: './control-notifications-add.component.html',
  styleUrls: ['./control-notifications-add.component.scss'],
  animations: [titleAnimation],
})
export class ControlNotificationsAddComponent implements OnInit {
  title = 'Новое уведомление';
  StateValue = StateValue;

  private _submit$ = new Subject<AdminNotificationModel>();
  private _createNotification$ = (model: AdminNotificationModel) => this._adminNotificationsApi.create(model)
    .pipe(
      map(() => {
        setTimeout(() => {
          this._router.navigate(['/notifications'], { queryParams: this._activatedRoute.snapshot.queryParams });
        }, 1000);
        return { state: StateValue.Success, msg: 'Уведомление успешно сохранено' } as ComponentState
      })
      ,
      catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
    );

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _ui: UiService,
    private _titleService: Title,
    private _adminNotificationsApi: AdminNotificationsApiService,
  ) {
    this._ui.setTitle(this.title);
    this._titleService.setTitle(this.title);
  }

  isClients$: Observable<boolean> = this._activatedRoute.queryParams.pipe(map(params => params['type'] === 'clients'));

  formState$: Observable<ComponentState> = concat(
    of({ state: StateValue.Initial }),
    this._submit$
      .pipe(
        switchMap(
          model => concat(
            of({ state: StateValue.InProgress }),
            this._createNotification$(model),
          )
        )
      ),
  ).pipe(shareReplay(1));

  ngOnInit(): void {
  }

  submit(formValue: AdminNotificationModel) {
    this._submit$.next(formValue);
  }

}
