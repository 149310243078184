<div class="wrap">
    <input type="checkbox" [checked]="item.isReaded">
    <div class="info col">
        <p class="title"> {{item.subject}} </p>
        <p class="description"> {{item.text}} </p>
        <div class="bottom row">
            <div [ngClass]="{'impotance': true, 'important': item.priority > 0}">
                <ng-container *ngIf="item.priority > 0">важное</ng-container>
                <ng-container *ngIf="item.priority == 0">неважное</ng-container>
            </div>
            <span class="date">{{item.dateFrom | date:'dd.MM.y'}}</span>
        </div>
    </div>
</div>