import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminExchangeGetMessageRequestModel } from 'src/models/adminExchangeGetMessageRequestModel';
import { AdminExchangeMessageModel } from 'src/models/adminExchangeMessageModel';
import { AdminExchangeMessagesListRequestModel } from 'src/models/adminExchangeMessagesListRequestModel copy';
import { AdminExchangeMessagesListResponseModel } from 'src/models/adminExchangeMessagesListResponseModel';



@Injectable({
  providedIn: 'root'
})
export class AdminExchangeApiService {
  route = '/admin/exchange';
  constructor(private http: HttpClient) { }

  listMessages(model: AdminExchangeMessagesListRequestModel) {
    return this.http.post<AdminExchangeMessagesListResponseModel>(`${environment.baseUrl}${this.route}/listMessages`, model)
  }

  getMessage(model: AdminExchangeGetMessageRequestModel) {
    return this.http.post<AdminExchangeMessageModel>(`${environment.baseUrl}${this.route}/getMessage`, model)
  }

  runNavisionSync() {
    return this.http.post(`${environment.baseUrl}${this.route}/runNavisionSync`, {});
  }

}
