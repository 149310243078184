import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fakeImg'
})
export class FakeImgPipe implements PipeTransform {

  transform(value: string | null, defaultPlaceholder: string = '/assets/fake/gas.png'): string {
    return value ?? defaultPlaceholder;
  }

}
