<i-data-item [item]="item" [error]="error">
  <div *ngIf="form" class="wrap" @titleAnimation>
    <form [formGroup]="form" (ngSubmit)="submit()" class="col">
      <h4>Стоимость заказа документов, Руб.</h4>

      <i-form-item [control]="form.get('invoicePrice')">
        <label>Счет-фактура</label>
        <input formControlName="invoicePrice">
      </i-form-item>

      <i-form-item [control]="form.get('sertificatePrice')">
        <label>Сертификаты</label>
        <input formControlName="sertificatePrice">
      </i-form-item>

      <h4>Стоимость курьерской доставки, Руб.</h4>

      <i-form-item [control]="form.get('deliveryPrice')">
        <input formControlName="deliveryPrice">
      </i-form-item>

      <button type="submit" [disabled]="form.invalid">Сохранить</button>

    </form>
  </div>
</i-data-item>