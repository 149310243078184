<div class="wrap col" @titleAnimation>

    <div *ngIf="pageState$ |async as pageState" class="table">

        <ng-container [ngSwitch]="pageState.state">
            <ng-container *ngSwitchCase="StateValue.Success">

                <div *ngIf="pageState.data.items.length" class="table-body">
                    <a *ngFor="let item of pageState.data.items" [routerLink]="[item.id]"
                        class="table-body-row">
                        <div class="cell">{{item.name}}</div>
                    </a>
                </div>

                <div *ngIf="!pageState.data.items.length" class="msg warning">
                    Нет объектов для редактирования
                </div>

            </ng-container>

            <div class="center" *ngSwitchCase="StateValue.InProgress">
                <i-in-progress size="40px"></i-in-progress>
            </div>

            <app-state-message [state]="pageState" *ngSwitchCase="StateValue.Error"> </app-state-message>

        </ng-container>

    </div>
</div>