import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminOrdersListRequestModel } from 'src/models/adminOrdersListRequestModel';
import { AdminOrdersListResponseModel } from 'src/models/adminOrdersListResponseModel';


@Injectable({
  providedIn: 'root'
})
export class AdminOrdersApiService {
  route = '/admin/orders';
  constructor(private http: HttpClient) { }

  list(model: AdminOrdersListRequestModel) {
    return this.http.post<AdminOrdersListResponseModel>(`${environment.baseUrl}${this.route}/list`, model)
  }

}
