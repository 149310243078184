<div class="wrap col" @titleAnimation>
  <div class="filters row">
    <select (change)="filterOnlyActive($event)">
      <option *ngFor="let o of Dropdowns.filter" [value]="o.id"> {{o.name}} </option>
    </select>
    <select (change)="orderBy($event)">
      <option *ngFor="let o of Dropdowns.sort" [value]="o.id"> {{o.name}} </option>
    </select>
  </div>

  <div class="filters row">
    <input type="text" role="search" tabindex="0" class="search" placeholder="Поиск" (input)="search($event)">
  </div>

  <div class="row">
    <a [routerLink]="['add']" class="button"> Добавить </a>
  </div>

  <div *ngIf="pageState$ |async as pageState" class="table">

    <ng-container [ngSwitch]="pageState.state">
      <ng-container *ngSwitchCase="StateValue.Success">
        <div class="table-head">
          <div class="cell">Пользователь</div>
          <div class="cell">Роль</div>
          <div class="cell">Статус</div>
        </div>
        <div class="table-body">
          <a *ngFor="let item of pageState.data.items" [routerLink]="[item.userId]" class="table-body-row">
            <div class="cell"> {{item.name}} <span *ngIf="item.navisionId">({{item.navisionId}})</span> </div>
            <div class="cell"> {{item.roleName}} </div>
            <div [ngClass]="{'cell': true, 'inactive': !item.isActive}">
              {{item.isActive ? 'Активный' : 'Приостановлен'}}
            </div>
          </a>
        </div>

      </ng-container>

      <div class="center" *ngSwitchCase="StateValue.InProgress">
        <i-in-progress size="40px"></i-in-progress>
      </div>

      <app-state-message [state]="pageState" *ngSwitchCase="StateValue.Error"> </app-state-message>

    </ng-container>

    <linde-pagination [itemsQty]="pageState.data?.count" (onPageChanged)="onPageChanged($event)"></linde-pagination>

  </div>

</div>