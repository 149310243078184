import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerNotificationModel } from 'src/models/customerNotificationModel';

@Component({
  selector: 'linde-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() item: CustomerNotificationModel;
  @Output() onCheck = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  check() {
    this.onCheck.emit();
  }

}
