<div *ngIf="notificationState$ |async as notificationState" class="wrap col" @titleAnimation>

  <ng-container [ngSwitch]="notificationState.state">
    <app-notification-form *ngSwitchCase="StateValue.Success" [item]="notificationState.data"
      [formState]="formState$ |async" (onSubmit)="submit($event)">
    </app-notification-form>

    <app-state-message *ngSwitchCase="StateValue.Error" [state]="notificationState"></app-state-message>

    <div *ngSwitchCase="StateValue.InProgress" class="center">
      <i-in-progress></i-in-progress>
    </div>

  </ng-container>

</div>