import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminProductCategoriesEditRequestModel } from 'src/models/adminProductCategoriesEditRequestModel';
import { AdminProductCategoriesListRequestModel } from 'src/models/adminProductCategoriesListRequestModel';
import { AdminProductCategoriesListResponseModel } from 'src/models/adminProductCategoriesListResponseModel';
import { AdminProductCategoryIdModel } from 'src/models/adminProductCategoryIdModel copy';
import { AdminProductCategoryModel } from 'src/models/adminProductCategoryModel';


@Injectable({
  providedIn: 'root'
})
export class AdminProductCategoriesApiService {
  route = '/admin/productcategories';
  constructor(private http: HttpClient) { }

  list(model: AdminProductCategoriesListRequestModel) {
    return this.http.post<AdminProductCategoriesListResponseModel>(`${environment.baseUrl}${this.route}/list`, model)
  }

  get(model: AdminProductCategoryIdModel) {
    return this.http.post<AdminProductCategoryModel>(`${environment.baseUrl}${this.route}/get`, model)
  }

  edit(model: AdminProductCategoriesEditRequestModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/edit`, model)
  }

}
