import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, concat, map, merge, Observable, of, shareReplay, Subject, switchMap } from 'rxjs';
import { AdminUserModel } from 'src/models/adminUserModel';
import { AdminUsersCreateRequestModel } from 'src/models/adminUsersCreateRequestModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { encryptPassword } from 'src/modules/shared/helpers/encrypt-password';
import { Role, RoleList } from 'src/modules/shared/helpers/roles';
import { AdminUsersApiService } from 'src/services/api/admin-users.api.service';

@Component({
  selector: 'app-control-users-add',
  templateUrl: './control-users-add.component.html',
  styleUrls: ['./control-users-add.component.scss']
})
export class ControlUsersAddComponent implements OnInit {
  @Input() item: AdminUserModel;
  @Output() onSubmit = new EventEmitter<AdminUserModel>();

  StateValue = StateValue;

  private _submit$ = new Subject();
  private _createUser$ =
    (model: AdminUsersCreateRequestModel): Observable<ComponentState> =>
      this.adminUsersApi.create({ ...model, password: encryptPassword(model.password) })
        .pipe(
          map(() => {
            setTimeout(() => {
              this.router.navigate(['/users']);
            }, 1000);
            return { state: StateValue.Success, msg: 'Пользователь успешно сохранен' } as ComponentState
          })
          ,
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
        );

  form: UntypedFormGroup;
  roles: Role[] = RoleList;


  constructor(
    private router: Router,
    private adminUsersApi: AdminUsersApiService,
  ) { }

  private generateForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.item?.name ?? '', [Validators.required, Validators.maxLength(100)]),
      roleId: new UntypedFormControl(this.item?.roleId, [Validators.required]),
      isActive: new UntypedFormControl(this.item?.isActive ?? true),
      phone: new UntypedFormControl(this.item?.phone ?? ''),
      email: new UntypedFormControl(this.item?.email ?? '', [Validators.email]),
      password: new UntypedFormControl(''),
    });
  }

  ngOnInit(): void {
    this.generateForm();
  }

  formState$: Observable<ComponentState> = merge(
    of({ state: StateValue.Initial }),
    this._submit$
      .pipe(
        switchMap(
          () => concat(
            of({ state: StateValue.InProgress }),
            this._createUser$({ ...this.form.value }),
          )
        ),
        shareReplay(1),
      )
  );

  submit() {
    this._submit$.next(true);
  }

}
