<ng-container *ngIf="state">

  <i-collapsible [expanded]="!!state.msg">

    <div [ngClass]="{
        'error': state.state === StateValue.Error,
        'success': state.state === StateValue.Success
      }">
      {{state.msg}}
    </div>

  </i-collapsible>

</ng-container>