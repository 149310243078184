import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataService, Item } from 'src/services/api/data.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'linde-control-contracts',
  templateUrl: './control-contracts.component.html',
  styleUrls: ['./control-contracts.component.scss']
})
export class ControlContractsComponent implements OnInit {
  title = 'Договоры';
  error: string;
  list: Item[];

  constructor(
    private ui: UiService,
    private titleService: Title,
    private dataService: DataService,
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
    this.fetchData();
  }

  ngOnDestroy() {
    this.titleService.setTitle('Загрузка');
  }

  fetchData() {
    this.dataService.getItems();
    this.dataService.items$
      .subscribe(
        items => {
          this.list = items;
          this.error = null;
        },
        err => {
          this.error = 'ERROR';
        }
      );
  }
}
