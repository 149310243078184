import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'linde-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  pageQty: number = 0;
  activePage: number = 3;
  @Input() initialPage: number = 1;
  @Input() itemsQty: number = 0;
  itemsPerPage: number = 20;
  itemsPerPageList: number[] = [10,20,30,50];

  @Output() onPageChanged = new EventEmitter<PaginationValue>();

  get pages() {
    this.pageQty = Math.ceil(this.itemsQty / this.itemsPerPage);
    if (this.pageQty > 5) {
      if ((this.activePage < this.pageQty - 1) && (this.activePage > 4)) {
        return [this.activePage - 1, this.activePage, this.activePage + 1];
      }

      if (this.activePage >= this.pageQty - 2) {
        return [this.pageQty - 2, this.pageQty - 1, this.pageQty];
      }

      if (this.activePage <= 4) {
        return [1, 2, 3, 4, 5];
      }
    }
    return new Array(this.pageQty)
      .fill(0)
      .map((_, index) => index + 1);;
  }

  constructor() { }

  ngOnInit(): void {
    this.activePage = this.initialPage;
  }

  activatePage(p: number) {
    this.activePage = p;
    this.onPageChanged.emit({ page: this.activePage, itemsPerPage: this.itemsPerPage });
  }

  prev() {
    this.activatePage(
      this.activePage > 1
        ? this.activePage - 1
        : 1
    );
  }

  next() {
    this.activatePage(
      this.activePage < this.pageQty
        ? this.activePage + 1
        : this.pageQty
    );
  }

  changeItemsPerPage(e: Event) {
    this.itemsPerPage = +(e.target as HTMLSelectElement).value;
    this.activatePage(1);
    this.onPageChanged.emit({ page: this.activePage, itemsPerPage: this.itemsPerPage });
  }

}

export interface PaginationValue {
  itemsPerPage: number;
  page: number;
}
