import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, catchError, combineLatest, concat, debounceTime, distinctUntilChanged, map, Observable, of, share, switchMap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminFeedbackResponseModel } from 'src/models/adminFeedbackResponseModel';
import { PaginationValue } from 'src/modules/shared/components/pagination/pagination.component';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { Dropdowns } from 'src/modules/shared/helpers/dropdowns';
import { AdminFeedbackApiService } from 'src/services/api/admin-feedback.api.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-control-feedback',
  templateUrl: './control-feedback.component.html',
  styleUrls: ['./control-feedback.component.scss'],
  animations: [titleAnimation],
})
export class ControlFeedbackComponent {
  title = 'Уведомления: Обратная связь';
  Dropdowns = Dropdowns.feedbackList;
  StateValue = StateValue;

  private _ordering$ = new BehaviorSubject<string>(this.Dropdowns.sort[0].id);
  private _pagination$ = new BehaviorSubject<PaginationValue>({ itemsPerPage: 20, page: 1 });
  private _search$ = new BehaviorSubject<string>('');

  private _listApi$ =
    (ordering: string, pagination: PaginationValue, searchValue: string) =>
      this.feedbackApi.list({
        sortType: ordering,
        search: searchValue,
        limit: pagination.itemsPerPage,
        offset: (pagination.page - 1) * pagination.itemsPerPage,
      })
        .pipe(
          map(list => ({ state: StateValue.Success, data: list } as ComponentState<AdminFeedbackResponseModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message }))
        );

  constructor(
    private feedbackApi: AdminFeedbackApiService,
    private ui: UiService,
    private titleService: Title,
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  pageState$: Observable<ComponentState<AdminFeedbackResponseModel>> =
    combineLatest([
      this._ordering$,
      this._pagination$,
      this._search$.pipe(
        debounceTime(500),
        map(value => value?.length >= 3 ? value : ''),
        distinctUntilChanged(),
      ),
    ])
      .pipe(
        switchMap(combined => concat(
          of({ state: StateValue.InProgress }),
          this._listApi$(...combined),
        )),
        share(),
      );

  orderBy(e: Event) {
    this._ordering$.next((e.target as HTMLSelectElement).value);
  }

  search(e: Event) {
    this._search$.next((e.target as HTMLInputElement).value?.trim());
  }

  onPageChanged(pv: PaginationValue) {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    this._pagination$.next(pv);
  }

}
