<h2> Новый пользователь </h2>

<form [formGroup]="form" class="col" (ngSubmit)="submit()">
    <!-- <i-form-item [control]="form.get('userId')">
        <label> </label>
        <input type="text" formControlName="userId">
    </i-form-item> -->

    <i-form-item [control]="form.get('name')">
        <label> Имя </label>
        <input type="text" formControlName="name">
    </i-form-item>

    <i-form-item [control]="form.get('roleId')">
        <label> Роль </label>
        <select formControlName="roleId">
            <option *ngFor="let role of roles" [value]="role.id"> {{role.name}} </option>
        </select>
    </i-form-item>

    <i-form-item [control]="form.get('phone')">
        <label> Телефон </label>
        <input type="tel" formControlName="phone">
    </i-form-item>

    <i-form-item [control]="form.get('email')">
        <label> E-mail </label>
        <input type="email" formControlName="email">
    </i-form-item>

    <i-form-item [control]="form.get('password')">
        <label> Пароль </label>
        <input type="password" formControlName="password">
    </i-form-item>

    <!-- <i-form-item [control]="form.get('passwordConfirm')">
        <label> Подтверждение пароля </label>
        <input type="password" formControlName="passwordConfirm">
    </i-form-item> -->

    <i-form-item [control]="form.get('isActive')">
        <div class="row chb">
            <input type="checkbox" formControlName="isActive">
            <label> Активен </label>
        </div>
    </i-form-item>

    <app-state-message [state]="formState$ |async"></app-state-message>

    <button type="submit" *ngIf="formState$ |async as formState" [disabled]="form.invalid">
        <ng-container *ngIf="formState.state === StateValue.InProgress">
            <i-in-progress size="18px"></i-in-progress>
        </ng-container>
        <ng-container *ngIf="formState.state !== StateValue.InProgress">
            Сохранить
        </ng-container>
    </button>

</form>