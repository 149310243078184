import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminParamsDocDeliveryModel } from 'src/models/adminParamsDocDeliveryModel';
import { AdminParamsApiService } from 'src/services/api/admin-params.api.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'linde-control-docs',
  templateUrl: './control-docs.component.html',
  styleUrls: ['./control-docs.component.scss'],
  animations: [titleAnimation]
})
export class ControlDocsComponent implements OnInit {
  title = 'Заказ документов';
  error: string;
  item: AdminParamsDocDeliveryModel;
  form: UntypedFormGroup;

  constructor(
    private ui: UiService,
    private titleService: Title,
    private adminParamsApi: AdminParamsApiService,
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
    this.adminParamsApi.getDocsDeliveryParams()
      .subscribe({
        next: _item => {
          this.item = _item;
          this.generateForm();
        }
      });
  }

  ngOnDestroy() {
    this.titleService.setTitle('Загрузка');
  }

  private generateForm() {
    this.form = new UntypedFormGroup({
      invoicePrice: new UntypedFormControl(this.item.invoicePrice, [Validators.required]),
      sertificatePrice: new UntypedFormControl(this.item.sertificatePrice, [Validators.required]),
      deliveryPrice: new UntypedFormControl(this.item.deliveryPrice, [Validators.required]),
    });
  }

  submit() {
    this.adminParamsApi.editDocsDeliveryParams(this.item)
      .subscribe();
  }
}
