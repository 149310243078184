import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminCustomersListRequestModel } from 'src/models/adminCustomersListRequestModel';
import { AdminCustomersListResponseModel } from 'src/models/adminCustomersListResponseModel';



@Injectable({
  providedIn: 'root'
})
export class AdminCustomersApiService {
  route = '/admin/customers';
  constructor(private http: HttpClient) { }

  list(model: AdminCustomersListRequestModel) {
    return this.http.post<AdminCustomersListResponseModel>(`${environment.baseUrl}${this.route}/list`, model)
  }
}
