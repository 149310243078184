import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminProductIdModel } from 'src/models/adminProductIdModel';
import { AdminProductModel } from 'src/models/adminProductModel';
import { AdminProductsEditRequestModel } from 'src/models/adminProductsEditRequestModel';
import { AdminProductsListRequestModel } from 'src/models/adminProductsListRequestModel';
import { AdminProductsListResponseModel } from 'src/models/adminProductsListResponseModel';


@Injectable({
  providedIn: 'root'
})
export class AdminProductsApiService {
  route = '/admin/products';
  constructor(private http: HttpClient) { }

  list(model: AdminProductsListRequestModel) {
    return this.http.post<AdminProductsListResponseModel>(`${environment.baseUrl}${this.route}/list`, model)
  }

  get(model: AdminProductIdModel) {
    return this.http.post<AdminProductModel>(`${environment.baseUrl}${this.route}/get`, model)
  }

  edit(model: AdminProductsEditRequestModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/edit`, model)
  }

}
