<div class="wrap">
    <div class="top">
      <div class="container">
        <div class="col">
          <h6>Обслуживание клентов</h6>
          <a [routerLink]="['/feedback']" routerLinkActive="active" class="item">
            Связаться с нами
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
          <a [routerLink]="['/']" routerLinkActive="active" class="item">
            Политика конфиденциальности
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
        </div>
        <div class="col">
          <h6>Корпоративная информация</h6>
          <a [routerLink]="['/']" routerLinkActive="active" class="item">
            О компании Linde
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
          <a [routerLink]="['/']" routerLinkActive="active" class="item">
            Условия использования
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
          <a [routerLink]="['/']" routerLinkActive="active" class="item">
            Юридическая информация
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
        </div>
        <div class="col">
          <h6>Помощь</h6>
          <a [routerLink]="['/make-order']" routerLinkActive="active" class="item">
            Как сделать заказ
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
          <a [routerLink]="['/delivery-methods']" routerLinkActive="active" class="item">
            Доставка
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
          <a [routerLink]="['/']" routerLinkActive="active" class="item">
            Оплата
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
          <a [routerLink]="['/faq']" routerLinkActive="active" class="item">
            Часто задаваемые вопросы
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
          <a [routerLink]="['/control']" routerLinkActive="active" class="item">
            ПАНЕЛЬ УПРАВЛЕНИЯ
            <img src="/assets/icons/arrow-forward.svg" alt="-->">
          </a>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        Copyright &copy; 2021 Linde
      </div>
    </div>
  </div>
  