import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthAccountLoginRequestModel } from 'src/models/authAccountLoginRequestModel';
import { AuthAccountLoginResponseModel } from 'src/models/authAccountLoginResponseModel';
import { AuthAccountPasswordChangeRequestModel } from 'src/models/authAccountPasswordChangeRequestModel';
import { AuthAccountPasswordRememberRequestModel } from 'src/models/authAccountPasswordRememberRequestModel';
import { AuthAccountPasswordResetRequestModel } from 'src/models/authAccountPasswordResetRequestModel';
import { AuthAccountRegisterRequestModel } from 'src/models/authAccountRegisterRequestModel';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  route = '/auth/account';
  constructor(private http: HttpClient) { }

  login(model: AuthAccountLoginRequestModel) {
    return this.http.post<AuthAccountLoginResponseModel>(`${environment.baseUrl}${this.route}/login`, model)
  }

  register(model: AuthAccountRegisterRequestModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/register`, model)
  }

  passwordRemember(model: AuthAccountPasswordRememberRequestModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/passwordRemember`, model)
  }

  passwordReset(model: AuthAccountPasswordResetRequestModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/passwordReset`, model)
  }

  passwordChange(model: AuthAccountPasswordChangeRequestModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/passwordChange`, model)
  }

  logoff() {
    return this.http.post(`${environment.baseUrl}${this.route}/logoff`, {})
  }
}
