import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { catchError, concat, map, Observable, of } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminEmailsListResponseModel } from 'src/models/adminEmailsListResponseModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { AdminEmailsApiService } from 'src/services/api/admin-emails.api.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-control-emails',
  templateUrl: './control-emails.component.html',
  styleUrls: ['./control-emails.component.scss'],
  animations: [titleAnimation],
})
export class ControlEmailsComponent {
  title = 'Адреса для уведомлений';
  StateValue = StateValue;

  private _listApi$ =
    () => this._adminEmailsApi.list({})
      .pipe(
        map(list => ({ state: StateValue.Success, data: list } as ComponentState<AdminEmailsListResponseModel>)),
        catchError(err => of({ state: StateValue.Error, msg: err?.error?.message }))
      );

  constructor(
    private _adminEmailsApi: AdminEmailsApiService,
    private ui: UiService,
    private titleService: Title,
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  pageState$: Observable<ComponentState<AdminEmailsListResponseModel>> = concat(
    of({ state: StateValue.InProgress }),
    this._listApi$(),
  );

}
