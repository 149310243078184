<!-- <div *ngIf="pages.length && activePage > 1" class="item nav center" (click)="activatePage(1)">
    <img src="/assets/icons/chevron-back-x2.svg" alt="FIRST">
</div> -->
<div *ngIf="pages.length && activePage > 1" class="item nav center" (click)="prev()">
    <img src="/assets/icons/chevron-back.svg" alt="BACK">
</div>

<ng-container *ngIf="pageQty > 5 && activePage > 4">

    <div class="item center" (click)="activatePage(1)">1</div>
    <div class="dots"> . . . </div>

</ng-container>


<div *ngFor="let p of pages" [ngClass]="{'item': true,'center': true, 'active': p === activePage}"
    (click)="activatePage(p)"> {{p}} </div>

<ng-container *ngIf="pageQty > 5 && activePage < pageQty - 1">

    <div class="dots"> . . . </div>
    <div class="item center" (click)="activatePage(pageQty)">{{pageQty}}</div>

</ng-container>

<div *ngIf="pages.length && activePage < pageQty" class="item nav center" (click)="next()">
    <img src="/assets/icons/chevron-forward.svg" alt="NEXT">
</div>

<!-- <div *ngIf="pages.length && activePage < pageQty" class="item nav center" (click)="activatePage(pageQty)">
    <img src="/assets/icons/chevron-forward-x2.svg" alt="LAST">
</div> -->

<select *ngIf="pages.length" (change)="changeItemsPerPage($event)">
    <option *ngFor="let v of itemsPerPageList" [value]="v" [selected]="v === itemsPerPage"> По {{v}} </option>
</select>