import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token$ = new BehaviorSubject<string>(null);

  private TOKEN_KEY: string = 'LINDE_AUTH_TOKEN';
  private TOKEN_VALUE: string = null;
  constructor() {
    this.TOKEN
  }

  get TOKEN() {
    let token = this.TOKEN_VALUE;
    if (!token) {
      token = JSON.parse(localStorage.getItem(this.TOKEN_KEY));
      if (token) {
        this.TOKEN_VALUE = token;
      }
    }
    this.token$.next(token);
    return token;
  }

  login(token: string) {
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(token));
    this.TOKEN_VALUE = token;
    this.token$.next(token);
  }

  logout() {
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(null));
    this.TOKEN_VALUE = null;
    this.token$.next(null);
  }
}
