import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, concat, map, Observable, of, switchMap, take } from 'rxjs';
import { AdminContentPagesListRequestModel } from 'src/models/adminContentPagesListRequestModel';
import { AdminContentPagesListResponseModel } from 'src/models/adminContentPagesListResponseModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { PaginationValue } from 'src/modules/shared/components/pagination/pagination.component';
import { AdminContentApiService } from 'src/services/api/admin-content.api.service';
import { titleAnimation } from 'src/animations/title.animation';
import { Title } from '@angular/platform-browser';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-control-content',
  templateUrl: './control-content.component.html',
  styleUrls: ['./control-content.component.scss'],
  animations: [titleAnimation]
})
export class ControlContentComponent {
  title = 'Контент';
  activatedRowId: number;

  StateValue = StateValue;

  private _pagination$ = new BehaviorSubject<PaginationValue>({ itemsPerPage: 20, page: 1 });

  private _getListApi$(model: AdminContentPagesListRequestModel): Observable<ComponentState<AdminContentPagesListResponseModel>> {
    return this.contentApi.list(model)
      .pipe(
        map(list => ({ state: StateValue.Success, data: list } as ComponentState<AdminContentPagesListResponseModel>)),
        catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
        take(1),
      );
  }

  constructor(
    private ui: UiService,
    private titleService: Title,
    private contentApi: AdminContentApiService,
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  pageState$: Observable<ComponentState<AdminContentPagesListResponseModel>> =
    this._pagination$.pipe(
      switchMap(pagination => concat(
        of({ state: StateValue.InProgress }),
        this._getListApi$({
          limit: pagination.itemsPerPage,
          offset: (pagination.page - 1) * pagination.itemsPerPage,
        }),
      )),
    );


  getChildrenApi =
    (parentCode: string): Observable<ComponentState<AdminContentPagesListResponseModel>> => {
      return this._getListApi$({ parentCode });
    }

  // contentChildren$ =
  //   (parentId: number): Observable<ComponentState<AdminContentPagesListResponseModel>> =>
  //     concat(
  //       of({ state: StateValue.InProgress }),
  //       of({ state: StateValue.Success }),
  //       // this._getListApi$({ parentId }),
  //     );

  onPageChanged(pv: PaginationValue) {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    this._pagination$.next(pv);
  }

  toggleRow(id: number) {
    this.activatedRowId = this.activatedRowId === id
      ? null
      : id;
  }

}
