<div *ngIf="feedbackState$ |async as feedbackState" class="wrap col" @titleAnimation>


    <ng-container [ngSwitch]="feedbackState.state">

        <div *ngSwitchCase="StateValue.Success" class="col">
            <h2> Сообщение </h2>
            <!-- <p class="item">
                <span class="title"> Идентификатор: </span>
                <span class="value"> {{feedbackState.data.feedbackId}} </span>
            </p> -->
            <p class="item">
                <span class="title"> Имя клиента: </span>
                <span class="value"> {{feedbackState.data.customerName}} </span>
            </p>
            <p class="item">
                <span class="title"> Тип: </span>
                <span class="value"> {{feedbackState.data.feedbackTypeName}} </span>
            </p>
            <p class="item">
                <span class="title"> Сообщение: </span>
                <span class="value"> {{feedbackState.data.message}} </span>
            </p>
            <div class="files">
                <span class="title"> Файлы: </span>
                <div class="value">
                    <p *ngFor="let file of feedbackState.data.files">
                        <a [href]="file.url" [download]="file.fileName"> {{file.fileName}} </a>
                    </p>
                </div>
            </div>
        </div>

        <div class="center" *ngSwitchCase="StateValue.InProgress">
            <i-in-progress size="40px"></i-in-progress>
        </div>

        <app-state-message [state]="feedbackState" *ngSwitchCase="StateValue.Error"> </app-state-message>
    </ng-container>

</div>