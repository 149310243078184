<div class="wrap col" @titleAnimation>

  <div class="sync col">
    <button type="submit" *ngIf="syncState$ |async as syncState" [disabled]="syncState.state === StateValue.InProgress"
      (click)="runSync()">
      <ng-container *ngIf="syncState.state === StateValue.InProgress">
        <i-in-progress size="18px"></i-in-progress>
      </ng-container>
      <ng-container *ngIf="syncState.state !== StateValue.InProgress">
        Запуск синхронизации
      </ng-container>
    </button>

    <app-state-message [state]="syncState$ |async"></app-state-message>
  </div>

  <div class="filters row">
    <input type="text" role="search" tabindex="0" class="search" placeholder="Поиск" (input)="search($event)">

    <select (change)="orderBy($event)">
      <option *ngFor="let o of Dropdowns.sort" [value]="o.id"> {{o.name}} </option>
    </select>

    <div class="row error-only">
      <input type="checkbox" id="errorOnly" (change)="toggleErrorOnly($event)">
      <label for="errorOnly"> Только&nbsp;ошибки </label>
    </div>
  </div>

  <div *ngIf="pageState$ |async as pageState" class="table">

    <ng-container [ngSwitch]="pageState.state">
      <ng-container *ngSwitchCase="StateValue.Success">

        <div class="table-head">
          <div class="cell">Дата</div>
          <div class="cell">Тип</div>
          <div class="cell">Направление</div>
          <div class="cell">Статус</div>
        </div>

        <div class="table-body">
          <div *ngFor="let item of pageState.data.items" class="table-body-row" [routerLink]="[item.exchangeMessageId]">
            <div class="cell"> {{item.messageDate | date:'dd.MM.y HH:mm:ss'}} </div>
            <div class="cell"> {{item.messageType}} </div>
            <div class="cell"> {{item.direction}} </div>
            <div [ngClass]="{'cell': true, 'error': item.status === 'Ошибка обработки'}">
              {{item.status}}
            </div>
          </div>
        </div>

        <div *ngIf="!pageState.data.items.length" class="msg warning">
          Нет данных
        </div>

      </ng-container>

      <div class="center" *ngSwitchCase="StateValue.InProgress">
        <i-in-progress size="40px"></i-in-progress>
      </div>

      <app-state-message [state]="pageState" *ngSwitchCase="StateValue.Error"> </app-state-message>

    </ng-container>

    <linde-pagination [itemsQty]="pageState.data?.count" (onPageChanged)="onPageChanged($event)"></linde-pagination>

  </div>
</div>