<form *ngIf="form && formState" [formGroup]="form" class="col" (ngSubmit)="submit()">

    <i-form-item [control]="form.get('emails')">
        <label> Список адресов (через точку с запятой) </label>
        <textarea formControlName="emails" rows="6"></textarea>
    </i-form-item>

    <app-state-message [state]="formState"></app-state-message>

    <button type="submit" [disabled]="form.invalid || formState.state === StateValue.InProgress">
        <ng-container *ngIf="formState.state !== StateValue.InProgress; else inProgress">
            Сохранить
        </ng-container>
        <ng-template #inProgress>
            <i-in-progress *ngIf="formState.state === StateValue.InProgress" size="24px"> </i-in-progress>
        </ng-template>
    </button>

</form>