<div *ngIf="emailsState$ |async as emailsState" class="wrap col" @titleAnimation>

    <ng-container [ngSwitch]="emailsState.state">
        <app-emails-form *ngSwitchCase="StateValue.Success" [item]="emailsState.data" [formState]="formState$ |async"
            (onSubmit)="submit($event)">
        </app-emails-form>

        <app-state-message *ngSwitchCase="StateValue.Error" [state]="emailsState"></app-state-message>

        <div *ngSwitchCase="StateValue.InProgress" class="center">
            <i-in-progress></i-in-progress>
        </div>

    </ng-container>

</div>