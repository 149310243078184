import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from 'src/services/api/auth.api.service';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  authorized: boolean;

  constructor(
    private _authService: AuthService,
    private _authApiService: AuthApiService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this._authService.token$
      .subscribe(t => {
        this.authorized = !!t;
      });
  }

  logout() {
    this._authApiService.logoff()
      .subscribe(() => {
        this._authService.logout();
        this._router.navigate(['/enter']);
      });
  }
}
