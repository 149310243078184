<div *ngIf="uploadedFileState$ |async as uploadedFileState" class="wrap col">

    <!-- <p *ngIf="uploadedFileState.state === StateValue.Success" class="file-name">
        {{uploadedFileState.data}}
    </p> -->
    <div *ngIf="uploadedFileState.state !== StateValue.InProgress; else inProgress" (click)="clickFileInput()">
        <ng-content></ng-content>
    </div>
    <ng-template #inProgress>
        <i-in-progress></i-in-progress>
    </ng-template>

    <app-state-message *ngIf="uploadedFileState.state === StateValue.Error" [state]="uploadedFileState">
    </app-state-message>

    <input #file type="file" [disabled]="uploadedFileState.state === StateValue.InProgress"
        (change)="onFileChanged($event)">
</div>