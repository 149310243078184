<div class="wrap">
    <div class="head" (click)="toggle()">
        <div class="cell">{{item.orderNumber}}</div>
        <div class="cell">{{item.orderDate | date:'dd.MM.y'}}</div>
        <div class="cell">{{item.totalSum | number:'1.2-2' | replace:',':' '}} &#8381;</div>
        <div [ngClass]="{'cell': true, 'active': item.statusId === 0, 'in-progress': item.statusId === 1}">
            {{item.statusName}}
        </div>
        <img [ngClass]="{'arrow': true, 'active': expanded}" src="/assets/icons/chevron-down.svg" alt="ARROW">
    </div>
    <i-collapsible [expanded]="expanded">
        <div class="description">
            <!-- TODO: as separated component -->
            <div class="status">
                <div class="roadmap">
                    <div [ngClass]="{'circle': true, 'active': item.statusId >= 10}"></div>
                    <div [ngClass]="{'line': true, 'active': item.statusId >= 20}"></div>
                    <div [ngClass]="{'circle': true, 'active': item.statusId >= 20}"></div>
                    <div [ngClass]="{'line': true, 'active': item.statusId >= 30}"></div>
                    <div [ngClass]="{'circle': true, 'active': item.statusId >= 30}"></div>
                    <div [ngClass]="{'line': true, 'active': item.statusId >= 40}"></div>
                    <div [ngClass]="{'circle': true, 'active': item.statusId >= 40}"></div>
                    <div [ngClass]="{'line': true, 'active': item.statusId >= 50}"></div>
                    <div [ngClass]="{'circle': true, 'active': item.statusId >= 50}"></div>
                    <div [ngClass]="{'line': true, 'active': item.statusId >= 60}"></div>
                    <div [ngClass]="{'circle': true, 'active': item.statusId >= 60}"></div>
                </div>
                <!-- TODO: STATUS CODES -->
                <div class="captions">
                    <div [ngClass]="{'captions-item': true, 'active': item.statusId === 10}">Создан</div>
                    <div [ngClass]="{'captions-item': true, 'active': item.statusId === 20}">Ожидает оплаты</div>
                    <div [ngClass]="{'captions-item': true, 'active': item.statusId === 30}">В производстве</div>
                    <div [ngClass]="{'captions-item': true, 'active': item.statusId === 40}">Готов к отгрузке</div>
                    <div [ngClass]="{'captions-item': true, 'active': item.statusId === 50}">В доставке</div>
                    <div [ngClass]="{'captions-item': true, 'active': item.statusId === 60}">Получен</div>
                </div>
            </div>

            <div class="composition col">
                <h4>Состав заказа</h4>
                <div *ngFor="let i of item.details" class="composition-item row">
                    <img [src]="i.media?.photo640 ?? i.media?.photo640 |fakeImg" alt="GAS">
                    <div class="info col">
                        <p class="title"> {{i.name}} </p>
                        <p *ngIf="i.code" class="article">арт. {{i.code}} </p>
                    </div>
                    <div class="qty"> {{i.qty}} {{i.measureUnit}} </div>
                    <div class="prices col">
                        <p class="price">
                            {{i.totalSum | number:'1.2-2' | replace:',':' '}} &#8381;
                        </p>
                        <p class="price-without-tax">
                            {{(i.totalSum - i.vatSum) | number:'1.2-2' | replace:',':' '}} &#8381; без НДС
                        </p>
                    </div>
                </div>

                <div *ngIf="!item.details?.length" class="msg warning">
                    Нет данных
                </div>
            </div>

            <div class="edition-info col">
                <p *ngIf="item.deliverySum">
                    <strong>Стоимость доставки:</strong>
                    {{item.deliverySum | number:'1.2-2' | replace:',':' '}} &#8381;
                </p>
                <p *ngIf="item.deliveryAddress"><strong>Адрес доставки:</strong> {{item.deliveryAddress?.address}} </p>
                <p *ngIf="item.deliveryDate"><strong>Дата доставки:</strong> {{item.deliveryDate | date:'dd.MM.y'}}</p>
                <p *ngIf="item.deliveryDriver"><strong>Водитель:</strong> {{item.deliveryDriver}} </p>
            </div>

            <!-- <a href="#" class="repeat-order">Повторить заказ</a> -->
        </div>
    </i-collapsible>

</div>