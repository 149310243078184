import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { catchError, concat, map, merge, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminNotificationModel } from 'src/models/adminNotificationModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { AdminNotificationsApiService } from 'src/services/api/admin-notifications.api.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'linde-control-notifications-edit',
  templateUrl: './control-notifications-edit.component.html',
  styleUrls: ['./control-notifications-edit.component.scss'],
  animations: [titleAnimation]
})
export class ControlNotificationsEditComponent {
  title = 'Уведомдения / Редактирование уведомления';
  StateValue = StateValue;
  // id: string;
  // item: AdminNotificationModel;

  private _getNotificationApi$ =
    (notificationId: string): Observable<ComponentState<AdminNotificationModel>> =>
      this.adminNotificationsApi.get({ notificationId })
        .pipe(
          map(item => {
            return ({ state: StateValue.Success, data: item } as ComponentState<AdminNotificationModel>);
          }),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
        );

  private _updateNotificationApi$ = (model: AdminNotificationModel) =>
    this.adminNotificationsApi.edit(model)
      .pipe(
        map(() => ({ state: StateValue.Success, msg: 'Уведомление успешно обновлено.' } as ComponentState)),
        catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
      );

  private _submitForm$ = new Subject<AdminNotificationModel>();


  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private adminNotificationsApi: AdminNotificationsApiService,
    private ui: UiService
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  notificationState$: Observable<ComponentState<AdminNotificationModel>> = this.activatedRoute.params
    .pipe(
      switchMap(params => merge(
        of({ state: StateValue.InProgress }),
        this._getNotificationApi$(params['id'])
      )),
      tap(console.log),
      shareReplay(1),
    );

  formState$: Observable<ComponentState> = concat(
    of({ state: StateValue.Initial }),
    this._submitForm$
      .pipe(
        switchMap(
          model => concat(
            of({ state: StateValue.InProgress }),
            this._updateNotificationApi$(model),
          )
        ),
      ),
  )
    .pipe(shareReplay(1));

  submit(formValue: AdminNotificationModel) {
    this._submitForm$.next(formValue);
  }
}
