<div class="wrap col" @titleAnimation>

  <div class="head row">
    <div class="tabs-head">
      <button *ngFor="let o of Dropdowns.filter" type="button" [ngClass]="{'active': o.id}"
        (click)="filterOnlyActive(o.id)">
        {{o.name}}
      </button>
    </div>

    <select (change)="orderBy($event)">
      <option *ngFor="let o of Dropdowns.sort" [value]="o.id"> {{o.name}} </option>
    </select>
  </div>

  <div class="head row">
    <input type="text" role="search" tabindex="0" class="search" placeholder="Поиск" (input)="search($event)">
  </div>

  <div *ngIf="pageState$ |async as pageState" class="wrap col" @titleAnimation>

    <ng-container [ngSwitch]="pageState.state">
      <ng-container *ngSwitchCase="StateValue.Success">

        <div class="table">
          <div class="table-head">
            <div class="cell">Номер</div>
            <div class="cell">Дата заказа</div>
            <div class="cell">Сумма</div>
            <div class="cell">Статус</div>
          </div>

          <div class="table-body">
            <linde-order *ngFor="let item of pageState.data.items" [item]="item"
              [expanded]="item.orderId === activeItemId" (onToggle)="toggle($event)"></linde-order>
          </div>
        </div>

      </ng-container>

      <div class="center" *ngSwitchCase="StateValue.InProgress">
        <i-in-progress size="40px"></i-in-progress>
      </div>

      <app-state-message [state]="pageState" *ngSwitchCase="StateValue.Error"> </app-state-message>

    </ng-container>

    <linde-pagination [itemsQty]="pageState.data?.count" (onPageChanged)="onPageChanged($event)"></linde-pagination>

  </div>

</div>