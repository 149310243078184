<div *ngIf="briefMessageState$ |async as briefMessageState" class="wrap col" @titleAnimation>

    <h2> Сообщение </h2>

    <ng-container [ngSwitch]="briefMessageState.state">

        <div *ngSwitchCase="StateValue.Success" class="col">
            <p class="item">
                <span class="title"> Идентификатор: </span>
                <span class="value"> {{briefMessageState.data.exchangeMessageId}} </span>
            </p>
            <p class="item">
                <span class="title"> Дата: </span>
                <span class="value"> {{briefMessageState.data.messageDate | date:'y-MM-dd'}} </span>
            </p>
            <p class="item">
                <span class="title"> Тип: </span>
                <span class="value"> {{briefMessageState.data.messageType}} </span>
            </p>
            <p class="item">
                <span class="title"> Направление: </span>
                <span class="value"> {{briefMessageState.data.direction}} </span>
            </p>
            <p class="item">
                <span class="title"> Статус: </span>
                <span [ngClass]="{'value': true, 'success': briefMessageState.data.status === 'Обработано успешно'}">
                    {{briefMessageState.data.status}}
                </span>
            </p>
            <p class="item">
                <span class="title"> Результат: </span>
                <span class="value"> {{briefMessageState.data.result ?? '-'}} </span>
            </p>

            <button (click)="descriptionShown = true"> Показать текст сообщения </button>

            <ng-container *ngIf="descriptionShown">
                <ng-container [ngTemplateOutlet]="descriptionTemplate"></ng-container>
            </ng-container>

        </div>

        <div class="center" *ngSwitchCase="StateValue.InProgress">
            <i-in-progress size="40px"></i-in-progress>
        </div>

        <app-state-message [state]="briefMessageState" *ngSwitchCase="StateValue.Error"> </app-state-message>
    </ng-container>

</div>

<ng-template #descriptionTemplate>
    <div *ngIf="messageDescriptionState$ |async as messageDescriptionState" class="wrap col" @titleAnimation>

        <!-- {{messageDescriptionState |json}} -->
        <ng-container [ngSwitch]="messageDescriptionState.state">

            <ng-container *ngIf="!!messageDescriptionState.data; else empty">
                <pre *ngSwitchCase="StateValue.Success">
                    {{messageDescriptionState.data |json}}
                </pre>
            </ng-container>
            <ng-template #empty>
                <div class="msg warning"> Нет данных </div>
            </ng-template>
            

            <div class="center" *ngSwitchCase="StateValue.InProgress">
                <i-in-progress size="40px"></i-in-progress>
            </div>

            <app-state-message [state]="messageDescriptionState" *ngSwitchCase="StateValue.Error"> </app-state-message>
        </ng-container>

    </div>
</ng-template>