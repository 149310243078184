import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'i-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  @Input() initialValue: number;
  @Input() mini: boolean = false;
  @Output() onChange = new EventEmitter<number>();
  value: number;
  constructor() { }

  ngOnInit(): void {
    this.value = this.initialValue ?? 0;
  }

  minus() {
    if (this.value > 0) {
      this.value--;
      this.onChange.emit(this.value)
    }
  }

  plus() {
    this.value++;
    this.onChange.emit(this.value)
  }

}
