import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminParamsDocDeliveryModel } from 'src/models/adminParamsDocDeliveryModel';


@Injectable({
  providedIn: 'root'
})
export class AdminParamsApiService {
  route = '/admin/params';
  constructor(private http: HttpClient) { }

  getDocsDeliveryParams() {
    return this.http.post<AdminParamsDocDeliveryModel>(`${environment.baseUrl}${this.route}/getDocsDeliveryParams`, {})
  }

  editDocsDeliveryParams(model: AdminParamsDocDeliveryModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/editDocsDeliveryParams`, model)
  }

}
