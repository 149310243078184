import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { catchError, concat, map, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminProductModel } from 'src/models/adminProductModel';
import { AdminProductsEditRequestModel } from 'src/models/adminProductsEditRequestModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { MediaIdModel } from 'src/models/mediaIdModel';
import { AdminProductsApiService } from 'src/services/api/admin-products.api.service';
import { DataService, Item } from 'src/services/api/data.service';
import { UiService } from 'src/services/ui.service';
import { MediaModel } from 'src/models/mediaModel';
import { AdminMediaApiService } from 'src/services/api/admin-media.api.service';

@Component({
  selector: 'linde-control-goods-item',
  templateUrl: './control-goods-item.component.html',
  styleUrls: ['./control-goods-item.component.scss'],
  animations: [titleAnimation]
})
export class ControlGoodsItemComponent {
  title = 'Каталог товаров / ';
  // error: string;
  form: UntypedFormGroup;
  // id: string;
  // item: AdminProductModel;
  StateValue = StateValue;
  images: MediaModel[] = [];


  private _getProductApi$ =
    (productId: string): Observable<ComponentState<AdminProductModel>> =>
      this.adminProductsApi.get({ productId })
        .pipe(
          map(item => ({ state: StateValue.Success, data: item } as ComponentState<AdminProductModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
          tap((currentState: ComponentState<AdminProductModel>) => {
            if (currentState.state === StateValue.Success)
              this.images = currentState.data?.media ?? [];
          }),
        );

  private _updateProductApi$ =
    (model: AdminProductsEditRequestModel): Observable<ComponentState> =>
      this.adminProductsApi.edit(model)
        .pipe(
          map(() => ({ state: StateValue.Success, msg: 'Продукт успешно обновлен' } as ComponentState)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
        );

  private _submit$ = new Subject<AdminProductsEditRequestModel>();

  constructor(
    private ui: UiService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private adminProductsApi: AdminProductsApiService,
    public fileApi: AdminMediaApiService,
  ) {
    this.ui.setTitle(this.title + '...');
  }

  productState$: Observable<ComponentState<AdminProductModel>> = this.activatedRoute.params
    .pipe(
      switchMap(
        params => concat(
          of({ state: StateValue.InProgress }),
          this._getProductApi$(params['id'])
            .pipe(tap(state => {
              if (state.state = StateValue.Success) {
                this.ui.setTitle(this.title + state.data.name);
                this.titleService.setTitle(this.title + state.data.name);
                this.generateForm(state.data);
              }
            })),
        )
      ),
      shareReplay(1),
    );

  updateProductState$: Observable<ComponentState> = concat(
    of({ state: StateValue.Initial }),
    this._submit$.pipe(
      switchMap(model => concat(
        of({ state: StateValue.InProgress }),
        this._updateProductApi$(model),
      ))
    )
  )
    .pipe(shareReplay(1));

  onImagesLoaded(model: MediaModel) {
    this.form.patchValue({ mediaAddIds: [{ mediaResourceId: model.mediaId }] });
    this.images.push(model);
  }

  removeImage(mediaId: string) {
    this.images = this.images.filter(i => i.mediaId !== mediaId);
    this.form.patchValue({ mediaDeleteIds: [{ mediaId }] });
  }

  private generateForm(item: AdminProductModel) {
    this.form = new UntypedFormGroup({
      productId: new UntypedFormControl(item.productId, [Validators.required]),
      detailText: new UntypedFormControl(item.detailText, [Validators.required]),
      mediaAddIds: new UntypedFormControl([]),
      mediaDeleteIds: new UntypedFormControl([]),
    });
  }

  addMedia(mediaId: string) {
    const mediaAddIds: MediaIdModel[] = this.form.get('mediaAddIds').value as MediaIdModel[];
    mediaAddIds.push({ mediaId });
    this.form.patchValue({ mediaAddIds });
  }

  deleteMedia(mediaId: string) {
    let mediaDeleteIds: MediaIdModel[] = this.form.get('mediaDeleteIds').value as MediaIdModel[];
    mediaDeleteIds = mediaDeleteIds.filter(m => m.mediaId !== mediaId);
    this.form.patchValue({ mediaDeleteIds });
  }

  submit() {
    this._submit$.next(this.form.value);
    // this.adminProductsApi.edit({})
  }
}
