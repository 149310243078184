<div *ngIf="productState$ |async as productState" class="wrap col" @titleAnimation>
  <ng-container *ngIf="productState.state === StateValue.Success">

    <h2> {{productState.data.name}} </h2>

    <form [formGroup]="form" (ngSubmit)="submit()" class="col">
      <i-form-item [control]="form.get('detailText')">
        <label> Детальное описание </label>
        <textarea type="text" formControlName="detailText" rows="10"></textarea>
      </i-form-item>

      <h4>Изображение товара</h4>

      <div class="images row">
        <div *ngFor="let image of images" class="images-item col">
          <img src="/assets/icons/close.svg" alt="CLOSE" class="icon" (click)="removeImage(image.mediaId)">
          <img [src]="image.photo640 ?? image.photoOriginal |fakeImg" alt="GAS" class="image">
        </div>
        <file-input *ngIf="!images.length" [fileApi]="fileApi" (onImagesLoaded)="onImagesLoaded($event)">
          <div class="images-item add">
            <img src="/assets/icons/add-circle-outline.svg" alt="ADD">
          </div>
        </file-input>
        <!-- <div class="images-item add">
          <img src="/assets/icons/add-circle-outline.svg" alt="ADD">
        </div> -->
      </div>

      <app-state-message [state]="updateProductState$ |async"></app-state-message>

      <button type="submit" *ngIf="updateProductState$ |async as updateProductState" [disabled]="form.invalid">
        <ng-container *ngIf="updateProductState.state === StateValue.InProgress">
          <i-in-progress size="18px"></i-in-progress>
        </ng-container>
        <ng-container *ngIf="updateProductState.state !== StateValue.InProgress">
          Сохранить
        </ng-container>
      </button>

    </form>
  </ng-container>

  <app-state-message [state]="productState"></app-state-message>

  <ng-container *ngIf="productState.state === StateValue.InProgress">
    <div class="center">
      <i-in-progress size="40px"></i-in-progress>
    </div>
  </ng-container>

</div>