<div class="input">
    <input #inputLink type="text" (input)="input($event)">
    <span *ngIf="value" class="clear-btn" (click)="clear()">x</span>
</div>
<div *ngIf="listState$ |async as listState" class="list-wrap">
    <div *ngIf="listState.state === StateValue.Success && isListVisible" class="list" @listAnimation>
        <div *ngFor="let item of listState.data" class="item" (click)="select(item)"> {{item.name}} </div>
        <div *ngIf="!listState.data.length" class="empty"> Клиенты не найдены </div>
    </div>
    <div *ngIf="listState.state === StateValue.InProgress" class="in-progress"> Загрузка . . . </div>
</div>