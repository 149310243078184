<div class="wrap col">

  <app-header></app-header>

  <div class="center">
    <div class="container">
      <i-breadcrumbs></i-breadcrumbs>
    </div>
  </div>

  <div class="center">
    <div class="container">
      <main>
        <h2 *ngIf="!isEnter">{{title}}</h2>

        <div class="cab">
          <linde-control-menu *ngIf="!isEnter"></linde-control-menu>
          <div class="cab-body">
            <router-outlet></router-outlet>
          </div>
        </div>
      </main>
    </div>
  </div>

  <!-- <app-footer></app-footer> -->

</div>


<!-- <div class="wrap">
  <div class="container">

    <i-breadcrumbs></i-breadcrumbs>

    <h2>{{title}}</h2>

    <div class="cab">
      <linde-control-menu></linde-control-menu>
      <div class="cab-body">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div> -->