import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminUserIdModel } from 'src/models/adminUserIdModel';
import { AdminUserModel } from 'src/models/adminUserModel';
import { AdminUsersCreateRequestModel } from 'src/models/adminUsersCreateRequestModel';
import { AdminUsersEditRequestModel } from 'src/models/adminUsersEditRequestModel';
import { AdminUsersListRequestModel } from 'src/models/adminUsersListRequestModel';
import { AdminUsersListResponseModel } from 'src/models/adminUsersListResponseModel';



@Injectable({
  providedIn: 'root'
})
export class AdminUsersApiService {
  route = '/admin/users';
  constructor(private http: HttpClient) { }

  list(model: AdminUsersListRequestModel) {
    return this.http.post<AdminUsersListResponseModel>(`${environment.baseUrl}${this.route}/list`, model)
  }

  get(model: AdminUserIdModel) {
    return this.http.post<AdminUserModel>(`${environment.baseUrl}${this.route}/get`, model)
  }

  create(model: AdminUsersCreateRequestModel) {
    return this.http.post<AdminUserModel>(`${environment.baseUrl}${this.route}/create`, model)
  }

  edit(model: AdminUsersEditRequestModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/edit`, model)
  }
}
