<div class="wrap col">

  <div class="col">

    <h4>Товары</h4>

    <a [routerLink]="['/goods/catalog']" routerLinkActive="active">
      Каталог товаров
    </a>
    <a [routerLink]="['/goods/categories']" routerLinkActive="active">
      Категории товаров
    </a>
    <!-- <a [routerLink]="['/goods/contracts']" routerLinkActive="active">
      Договоры
    </a> -->
  </div>

  <div class="col">

    <h4>Пользователи</h4>

    <a [routerLink]="['/users']" routerLinkActive="active">
      Список пользователей
    </a>
  </div>

  <div class="col">

    <h4>Заказы</h4>

    <a [routerLink]="['/orders']" routerLinkActive="active">
      Список заказов
    </a>
  </div>

  <div class="col">

    <h4>Контент</h4>

    <a [routerLink]="['/content']" routerLinkActive="active">
      Редактирование контента
    </a>
  </div>
  <div class="col">

    <h4>Уведомления</h4>

    <a [routerLink]="['/notifications']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      Общие уведомления
    </a>
    <a [routerLink]="['/notifications']" [queryParams]="{type: 'clients'}" routerLinkActive="active">
      Уведомления по клиентам
    </a>
    <a [routerLink]="['/notifications']" [queryParams]="{type: 'orders'}" routerLinkActive="active">
      Уведомления по заказам
    </a>
    <a [routerLink]="['/feedback']" routerLinkActive="active">
      Обратная связь
    </a>
    <!-- <a [routerLink]="['/settings/docs']" routerLinkActive="active">
      Заказ документов
    </a> -->
  </div>

  <div class="col">

    <h4>Служебные</h4>

    <a [routerLink]="['/data-exchange-log']" routerLinkActive="active">
      Журнал обмена данными
    </a>
    <a [routerLink]="['/emails']" routerLinkActive="active">
      Адреса для уведомлений
    </a>
  </div>

</div>