import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  items$: BehaviorSubject<Item[]> = new BehaviorSubject<Item[]>(null);
  constructor() { }

  getItems(): void {
    this.items$.next(null);
    setTimeout(() => {
      const items = [12, 45, 76, 35, 68, 113, 23].map(i => {
        return {
          id: i,
          name: `Супер газ ${i}`,
          description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, quas.',
          price: i * 20000 * (i % 3 + 1) - i,
          qty: i % 4 + 1,
          image: '/assets/fake/gas2.png',
          date: new Date()
        } as Item
      });
      this.items$.next(items);
    }, 1000);
  }
  
  getItem(id: number): Observable<Item> {
    return of({
      id,
      name: 'Ацетилен',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, quas.',
      price: 20150,
      qty: 16,
      image: '/assets/fake/gas2.png',
      date: new Date()
    } as Item);
  }
}

export class Item {
  id: number;
  name: string;
  description?: string;
  price?: number;
  qty?: number;
  image?: string;
  date?: Date;
}
