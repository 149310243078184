import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, concat, map, merge, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminEmailsModel } from 'src/models/adminEmailsModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { AdminEmailsApiService } from 'src/services/api/admin-emails.api.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-control-emails-edit',
  templateUrl: './control-emails-edit.component.html',
  styleUrls: ['./control-emails-edit.component.scss'],
  animations: [titleAnimation],
})
export class ControlEmailsEditComponent {

  title = 'Редактирование адресов для уведомлений';
  StateValue = StateValue;

  private _getNotificationApi$ =
    (id: string): Observable<ComponentState<AdminEmailsModel>> =>
      this._adminEmailsApi.get({ id })
        .pipe(
          map(item => {
            return ({ state: StateValue.Success, data: item } as ComponentState<AdminEmailsModel>);
          }),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
        );

  private _updateNotificationApi$ = (model: AdminEmailsModel) =>
    this._adminEmailsApi.edit(model)
      .pipe(
        map(() => ({ state: StateValue.Success, msg: 'Сохранено успешно.' } as ComponentState)),
        catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
      );

  private _submitForm$ = new Subject<AdminEmailsModel>();

  constructor(
    private _adminEmailsApi: AdminEmailsApiService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _ui: UiService,
    private _titleService: Title,
  ) {
    this._ui.setTitle(this.title);
    this._titleService.setTitle(this.title);
  }

  emailsState$: Observable<ComponentState<AdminEmailsModel>> = this._activatedRoute.params
    .pipe(
      switchMap(params => merge(
        of({ state: StateValue.InProgress }),
        this._getNotificationApi$(params['id'])
      )),
      shareReplay(1),
    );

  formState$: Observable<ComponentState> = concat(
    of({ state: StateValue.Initial }),
    this._submitForm$
      .pipe(
        switchMap(
          model => concat(
            of({ state: StateValue.InProgress }),
            this._updateNotificationApi$(model),
          )
        ),
        tap(currentState => {
          if (currentState.state === StateValue.Success) {
            setTimeout(() => {
              this._router.navigate(['/emails']);
            }, 1000);
          }
        })
      ),
  )
    .pipe(shareReplay(1));

  submit(formValue: AdminEmailsModel) {
    this._submitForm$.next(formValue);
  }
}
