import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminMediaCreateRequestModel } from 'src/models/adminMediaCreateRequestModel';
import { AdminMediaResourceIdModel } from 'src/models/adminMediaResourceIdModel';
import { MediaModel } from 'src/models/mediaModel';


@Injectable({
  providedIn: 'root'
})
export class AdminMediaApiService {
  route = '/admin/media';
  constructor(private http: HttpClient) { }

  create(file: File) {
    return this.http.post<MediaModel>(
      `${environment.baseUrl}${this.route}/create`,
      this._convertToFormData(file)
    );
  }

  delete(model: AdminMediaResourceIdModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/delete`, model)
  }

  makeDefault(model: AdminMediaResourceIdModel) {
    return this.http.post(`${environment.baseUrl}${this.route}/makeDefault`, model)
  }

  private _convertToFormData(file: File): FormData {
    const lastDotIndex: number = file.name.lastIndexOf('.');
    const extension: string = file.name.substring(lastDotIndex);
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('originalFileName', file.name);
    formData.append('fileExt', extension);
    return formData;
  }
}
