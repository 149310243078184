<div class="wrap col" @titleAnimation>
  <div class="filters row">
    <input type="text" role="search" tabindex="0" class="search" placeholder="Поиск" (input)="search($event)">

    <select (change)="orderBy($event)">
      <option *ngFor="let o of Dropdowns.sort" [value]="o.id"> {{o.name}} </option>
    </select>
  </div>

  <div class="row">

    <a *ngIf="(type$ |async) === 'clients'" [routerLink]="['add']" [queryParams]="{type: 'clients'}" class="button">
      Добавить
    </a>
    <a *ngIf="(type$ |async) !== 'clients'" [routerLink]="['add']" class="button">
      Добавить
    </a>


  </div>

  <div *ngIf="pageState$ |async as pageState" class="table">

    <ng-container [ngSwitch]="pageState.state">
      <ng-container *ngSwitchCase="StateValue.Success">

        <div class="table-head">
          <div class="cell">Текст сообщения</div>
          <div class="cell">Дата начала</div>
          <div class="cell">Дата окончания</div>
        </div>

        <div class="table-body">
          <ng-container *ngIf="type$ |async; else link">
            <div *ngFor="let item of pageState.data.items" class="table-body-row">
              <div class="cell">{{item.subject}}</div>
              <div class="cell">{{item.dateFrom | date:'dd.MM.y&nbsp;HH:mm'}}</div>
              <div class="cell">{{item.dateTo | date:'dd.MM.y&nbsp;HH:mm'}}</div>
            </div>
          </ng-container>
          <ng-template #link>
            <a *ngFor="let item of pageState.data.items" [routerLink]="[item.notificationId]" class="table-body-row">
              <div class="cell">{{item.subject}}</div>
              <div class="cell">{{item.dateFrom | date:'dd.MM.y&nbsp;HH:mm'}}</div>
              <div class="cell">{{item.dateTo | date:'dd.MM.y&nbsp;HH:mm'}}</div>
            </a>
          </ng-template>
        </div>

        <div *ngIf="!pageState.data.items.length" class="msg warning">
          Нет уведомлений
        </div>

      </ng-container>

      <div class="center" *ngSwitchCase="StateValue.InProgress">
        <i-in-progress size="40px"></i-in-progress>
      </div>

      <app-state-message [state]="pageState" *ngSwitchCase="StateValue.Error"> </app-state-message>

    </ng-container>

    <linde-pagination [itemsQty]="pageState.data?.count" (onPageChanged)="onPageChanged($event)"></linde-pagination>

  </div>
</div>