import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminFeedbackIdModel } from 'src/models/adminFeedbackIdModel';
import { AdminFeedbackModel } from 'src/models/adminFeedbackModel';
import { AdminFeedbackRequestModel } from 'src/models/adminFeedbackRequestModel';
import { AdminFeedbackResponseModel } from 'src/models/adminFeedbackResponseModel';



@Injectable({
  providedIn: 'root'
})
export class AdminFeedbackApiService {
  route = '/admin/feedback';
  constructor(private http: HttpClient) { }

  list(model: AdminFeedbackRequestModel) {
    return this.http.post<AdminFeedbackResponseModel>(`${environment.baseUrl}${this.route}/list`, model);
  }

  get(model: AdminFeedbackIdModel) {
    return this.http.post<AdminFeedbackModel>(`${environment.baseUrl}${this.route}/get`, model);
  }
}
