import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminCustomerModel } from 'src/models/adminCustomerModel';
import { AdminNotificationModel } from 'src/models/adminNotificationModel';
import { ISelectItem } from 'src/models/helpers/select-item.interface';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';

@Component({
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss']
})
export class NotificationFormComponent implements OnInit, OnChanges {
  StateValue = StateValue;
  private _item: AdminNotificationModel;
  @Input() set item(value: AdminNotificationModel) {
    this._item = value;
    this._generateForm();
  }
  @Input() formState: ComponentState;
  @Input() isClients: boolean = false;
  @Input() customers: AdminCustomerModel[] = [];

  @Output() onSubmit = new EventEmitter<AdminNotificationModel>();

  form: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
    this._generateForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('isClients' in changes)
      this._generateForm();
  }

  private _generateForm(): void {
    this.form = new UntypedFormGroup({
      notificationId: new UntypedFormControl(this._item?.notificationId),
      priority: new UntypedFormControl(this._item?.priority ?? 0, [Validators.required]),
      dateFrom: new UntypedFormControl(this._item?.dateFrom, [Validators.required]),
      dateTo: new UntypedFormControl(this._item?.dateTo, [Validators.required]),
      subject: new UntypedFormControl(this._item?.subject ?? '', [Validators.required]),
      text: new UntypedFormControl(this._item?.text ?? ''),
      isActive: new UntypedFormControl(this._item?.isActive ?? true, [Validators.required]),
    });
    if (this.isClients) {
      this.form.addControl('customerId', new UntypedFormControl(this._item?.customerId));
      this.form.addControl('customerName', new UntypedFormControl(this._item?.customerName));
      this.form.addControl('customer', new UntypedFormControl(this._item ? { id: this._item.customerId, name: this._item.customerName } : null));

      this.form.get('customer').valueChanges.subscribe((customer: ISelectItem) => {
        this.form.get('customerId').setValue(customer?.id);
        this.form.get('customerName').setValue(customer?.name);
      });
    }
  }

  submit() {
    const formValue = this.form.value;
    formValue.dateFrom += 'T00:00:00';
    formValue.dateTo += 'T00:00:00';
    this.onSubmit.emit(this.form.value);
  }
}
