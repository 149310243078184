import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';

@Component({
  selector: 'app-state-message',
  templateUrl: './state-message.component.html',
  styleUrls: ['./state-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateMessageComponent<T> {
  @Input() state: ComponentState<T>;
  StateValue = StateValue;
}
