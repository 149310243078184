import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { concat, debounceTime, delay, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { ISelectItem } from 'src/models/helpers/select-item.interface';
import { AdminCustomersApiService } from 'src/services/api/admin-customers.api.service';
import { ComponentState, StateValue } from '../../helpers/component-state.interface';

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    }
  ],
  animations: [
    trigger('listAnimation', [
      transition(':enter', [
        style({
          transform: 'translateY(30px)',
          opacity: '0'
        }),
        animate('.3s ease-out',
          style({
            transform: '*',
            opacity: '*'
          }))
      ]),
      transition(':leave', [
        style({
          transform: '*',
          opacity: '*'
        }),
        animate('.3s ease-out',
          style({
            transform: 'translateY(30px)',
            opacity: '0'
          }))
      ]),
    ]),
  ]
})
export class AutocompleteComponent implements AfterViewInit {
  private _input$ = new Subject<string>();

  private _disabled: boolean = false;
  get disabled(): boolean { return this._disabled; }
  @Input() set disabled(value: boolean) {
    this.setDisabledState(value)
  }

  private _value: ISelectItem = null;
  get value(): ISelectItem { return this._value; }
  set value(item: ISelectItem) {
    this._value = item;
    this.onChange(item);
    this.onTouch(item);
  }

  StateValue = StateValue;
  isListVisible = false;

  @ViewChild('inputLink') inputElem: ElementRef<HTMLInputElement>;

  constructor(private _adminCustomerApi: AdminCustomersApiService) { }

  ngAfterViewInit(): void {
    if (this.value)
      this.inputElem.nativeElement.value = this.value.name as string;
  }

  listState$: Observable<ComponentState<ISelectItem[]>> = concat(
    of({ state: StateValue.Initial }),
    this._input$.pipe(
      tap(() => {
        this.isListVisible = true;
      }),
      debounceTime(300),
      switchMap((value) => concat(
        of({ state: StateValue.InProgress }),
        value === null
          ? of({ state: StateValue.Initial })
          : this._adminCustomerApi.list({ search: value, isActive: true })
            .pipe(map(list => ({ state: StateValue.Success, data: list.items.map(i => ({ id: i.customerId, name: i.customerName } as ISelectItem)) })))
      ),
      )
    ),
  );

  onChange: any = () => { }

  onTouch: any = () => { }

  writeValue(item: ISelectItem): void {
    this.value = item
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  input(e: Event) {
    let value: string = (e.target as HTMLInputElement).value?.trim();
    if (value?.length >= 3) this._input$.next(value);
    else this.isListVisible = false;
  }

  clear() {
    this._input$.next(null);
    this.select(null);
  }

  select(item: ISelectItem) {
    this.writeValue(item);
    this.inputElem.nativeElement.value = !!item ? item.name as string : null;
    this._input$.next(null);
    this.isListVisible = false;
  }
}
