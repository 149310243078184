import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminCustomerModel } from 'src/models/adminCustomerModel';
import { AdminEmailsModel } from 'src/models/adminEmailsModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';

@Component({
  selector: 'app-emails-form',
  templateUrl: './emails-form.component.html',
  styleUrls: ['./emails-form.component.scss']
})
export class EmailsFormComponent implements OnInit{
  StateValue = StateValue;
  @Input() item: AdminEmailsModel;
  @Input() formState: ComponentState;

  @Output() onSubmit = new EventEmitter<AdminEmailsModel>();

  form: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
    this._generateForm();
  }

  private _generateForm(): void {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(this.item?.id),
      name: new UntypedFormControl(this.item?.name),
      emails: new UntypedFormControl(this.item?.emails),
    });
  }

  submit() {
    this.onSubmit.emit(this.form.value);
  }
}
