<a [routerLink]="['add']" [queryParams]="{parentCode: parentCode}" class="button"> Добавить </a>

<ng-container *ngIf="childrenState$ |async as childrenState">
    <ng-container [ngSwitch]="childrenState.state">
        <div class="list col" *ngSwitchCase="StateValue.Success">
            <a *ngFor="let child of childrenState.data.items" [routerLink]="[child.pageId]" class="item row">
                {{ child.subject }} <span *ngIf="child.code">({{child.code}})</span>
            </a>

            <div *ngIf="!childrenState.data?.items?.length" class="msg warning"> Список пуст </div>
        </div>

        <div class="center" *ngSwitchCase="StateValue.InProgress">
            <i-in-progress size="40px"></i-in-progress>
        </div>

        <app-state-message [state]="childrenState" *ngSwitchCase="StateValue.Error">
        </app-state-message>
    </ng-container>
</ng-container>