<div class="wrap col" @titleAnimation>

  <form *ngIf="userState$ |async as userState" [formGroup]="form" (ngSubmit)="submit()" class="col">
    <!-- <i-collapsible [expanded]="userState.state === StateValue.Success">
      <ng-container *ngIf="userState.state === StateValue.Success"> -->

    <div class="controls">

      <i-form-item [control]="form.get('name')">
        <div class="item row">
          <label> Пользователь </label>
          <input type="text" formControlName="name">
        </div>
      </i-form-item>

      <i-form-item [control]="form.get('phone')">
        <div class="item row">
          <label> Номер телефона </label>
          <input type="text" formControlName="phone">
        </div>
      </i-form-item>

      <i-form-item [control]="form.get('email')">
        <div class="item row">
          <label> Email </label>
          <input type="text" formControlName="email">
        </div>
      </i-form-item>

      <i-form-item [control]="form.get('roleId')">
        <div class="item row">
          <label>Роль</label>
          <select formControlName="roleId">
            <option *ngFor="let role of roles" [value]="role.id"> {{role.name}} </option>
          </select>
          <!-- <input type="text" formControlName="roleId"> -->
        </div>
      </i-form-item>

      <i-form-item [control]="form.get('isActive')">
        <div class="item row">
          <label> Активность </label>
          <input type="checkbox" formControlName="isActive">
        </div>
      </i-form-item>

      <i-form-item [control]="form.get('isChangePassword')">
        <div class="item row">
          <label> Изменить пароль </label>
          <input type="checkbox" formControlName="isChangePassword">
        </div>
      </i-form-item>

      <i-form-item [control]="form.get('password')" *ngIf="form.get('isChangePassword').value===true">
        <div class="item row">
          <label> Пароль </label>
          <input type="password" formControlName="password">
        </div>
      </i-form-item>

      <i-form-item [control]="form.get('passwordConfirm')" *ngIf="form.get('isChangePassword').value===true">
        <div class="item row">
          <label> Подтверждение пароля </label>
          <input type="password" formControlName="passwordConfirm">
        </div>
      </i-form-item>
    </div>


    <app-state-message [state]="updateUserState$ |async"></app-state-message>

    <button type="submit" *ngIf="updateUserState$ |async as updateUserState" [disabled]="form.invalid">
      <ng-container *ngIf="updateUserState.state === StateValue.InProgress">
        <i-in-progress size="18px"></i-in-progress>
      </ng-container>
      <ng-container *ngIf="userState.state !== StateValue.InProgress">
        Сохранить
      </ng-container>
    </button>

    <!-- </ng-container>
    </i-collapsible> -->

    <app-state-message [state]="userState"></app-state-message>

    <!-- <ng-container *ngIf="userState.state === StateValue.InProgress">
      <div class="center">
        <i-in-progress size="40px"></i-in-progress>
      </div>
    </ng-container> -->

  </form>

  <hr>

  <h4>Заказы пользователя</h4>

  <div *ngIf="ordersState$ |async as ordersState" class="orders">

    <ng-container [ngSwitch]="ordersState.state">
      <ng-container *ngSwitchCase="StateValue.Success">

        <div class="tabs-head">
          <button type="button" [ngClass]="{'active': !(isActive$ |async)}" (click)="filterOnlyActive(false)">
            Неактивные
          </button>
          <button type="button" [ngClass]="{'active': isActive$ |async}" (click)="filterOnlyActive(true)">
            Активные
          </button>
        </div>

        <div class="wrap col" @titleAnimation>
          <div class="table">
            <div class="table-head">
              <div class="cell">Номер</div>
              <div class="cell">Дата</div>
              <div class="cell">Сумма</div>
              <div class="cell">Статус</div>
            </div>
            <div class="table-body">
              <linde-order *ngFor="let order of ordersState.data.items" [item]="order"
                [expanded]="order.orderId === activeItemId" (onToggle)="toggle($event)"></linde-order>
            </div>
          </div>
        </div>

      </ng-container>

      <div class="center" *ngSwitchCase="StateValue.InProgress">
        <i-in-progress size="40px"></i-in-progress>
      </div>

      <app-state-message [state]="ordersState" *ngSwitchCase="StateValue.Error"> </app-state-message>

    </ng-container>

    <linde-pagination [itemsQty]="ordersState.data?.count" (onPageChanged)="onPageChanged($event)"></linde-pagination>

  </div>

</div>