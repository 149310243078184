<div class="center">
    <div class="container col">

        <h2>Авторизация на сайте</h2>

        <form [formGroup]="form" class="col" (ngSubmit)="submit()" autocomplete="off">
            <i-form-item [control]="form.get('login')">
                <input type="text" formControlName="login" placeholder="Имя пользователя" autocomplete="off">
            </i-form-item>
            <i-form-item [control]="form.get('password')">
                <input type="password" formControlName="password" placeholder="Пароль" autocomplete="off">
            </i-form-item>

            <app-state-message [state]="formState$ |async"></app-state-message>

            <button type="submit" *ngIf="formState$ |async as formState">
                <ng-container *ngIf="formState.state === StateValue.InProgress">
                    <i-in-progress size="18px"></i-in-progress>
                </ng-container>
                <ng-container *ngIf="formState.state !== StateValue.InProgress">
                    Войти
                </ng-container>
            </button>
        </form>

    </div>
</div>