import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { catchError, concat, map, Observable, of, switchMap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminFeedbackModel } from 'src/models/adminFeedbackModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { AdminFeedbackApiService } from 'src/services/api/admin-feedback.api.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-control-feedback-item',
  templateUrl: './control-feedback-item.component.html',
  styleUrls: ['./control-feedback-item.component.scss'],
  animations: [titleAnimation],
})
export class ControlFeedbackItemComponent {
  title = 'Сообщение обратной связи';
  StateValue = StateValue;

  private _itemApi$ =
    (feedbackId: string) =>
      this.feedbackApi.get({ feedbackId })
        .pipe(
          map(item => ({ state: StateValue.Success, data: item } as ComponentState<AdminFeedbackModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.message ?? err?.error?.message ?? 'Что-то пошло не так' }))
        );

  constructor(
    private feedbackApi: AdminFeedbackApiService,
    private activatedRoute: ActivatedRoute,
    private ui: UiService,
    private titleService: Title,
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  feedbackState$: Observable<ComponentState<AdminFeedbackModel>> = concat(
    of({ state: StateValue.InProgress }),
    this.activatedRoute.params
      .pipe(
        switchMap(
          params => this._itemApi$(params['id']),
        )
      ),
  );

}
