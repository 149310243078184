import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as errorList from './error-list.json';

@Component({
  selector: 'i-form-item',
  templateUrl: './form-item.component.html',
  styleUrls: ['./form-item.component.scss'],
})
export class FormItemComponent {
  @Input() control: AbstractControl;
  @Input() defaultMessage: string = 'Поле заполено некорректно';
  ErrorDescriptions = errorList;

  get errors(): any[] {
    const errorsArray: string[] = [];
    for (let key in this.control.errors) {
      errorsArray.push(key);
    }
 
    return errorsArray;
  }
}
