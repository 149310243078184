import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskDirective } from 'ngx-mask';

import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CollapsibleComponent } from './components/collapsible/collapsible.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { InProgressComponent } from './components/in-progress/in-progress.component';
import { ModalComponent } from './components/modal/modal.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ErrorComponent } from './components/error/error.component';
import { DataComponent } from './components/data/data.component';
import { ReplacePipe } from 'src/modules/shared/pipes/Replace';
import { RadioToggleComponent } from './components/radio-toggle/radio-toggle.component';
import { CounterComponent } from './components/counter/counter.component';
import { NotificationComponent } from './components/notification/notification.component';
import { DataItemComponent } from './components/data-item/data-item.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { StateMessageComponent } from './components/state-message/state-message.component';
import { FakeImgPipe } from './pipes/fake-img.pipe';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';



@NgModule({
  declarations: [
    AutocompleteComponent,
    BreadcrumbsComponent,
    CollapsibleComponent,
    CounterComponent,
    DataComponent,
    DataItemComponent,
    DropDownComponent,
    ErrorComponent,
    FileInputComponent,
    RadioToggleComponent,
    InProgressComponent,
    ModalComponent,
    NotificationComponent,
    PaginationComponent,
    SearchInputComponent,
    StateMessageComponent,
    ToggleComponent,

    ReplacePipe,
    FakeImgPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,

    AutocompleteComponent,
    BreadcrumbsComponent,
    CollapsibleComponent,
    CounterComponent,
    DataComponent,
    DataItemComponent,
    DropDownComponent,
    ErrorComponent,
    FileInputComponent,
    RadioToggleComponent,
    InProgressComponent,
    ModalComponent,
    NotificationComponent,
    PaginationComponent,
    SearchInputComponent,
    StateMessageComponent,
    ToggleComponent,

    ReplacePipe,
    FakeImgPipe,
  ]
})
export class SharedModule {
  // constructor(private vsService: ViewportSizeService) {
  //   vsService.init({ large: 1280, medium: 768 });
  // }
}
