import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { pageAnimation } from 'src/animations/page.animation';
import { BreadcrumbsService } from 'src/services/breadcrumbs.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [pageAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  title: string; 
  error: string;
  titleSubscription: Subscription;

  isEnter: boolean;

  constructor(
    private ui: UiService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
  ) {
    this.ui.hideMenuBlock();
  }

  ngOnInit(): void {
    this.breadcrumbsService.setItems([]);
    this.titleSubscription = this.ui.cabTitle$
      .subscribe(
        t => {
          this.title = t;
        }
      );

    this.router.events
      .subscribe(e => {
        if (e instanceof NavigationEnd) {
          this.isEnter = e.url === '/enter'
        }
      })
  }

  ngOnDestroy() {
    this.ui.showMenuBlock();
    this.titleSubscription.unsubscribe();
  }
}
