<div class="wrap col" @titleAnimation>
    <!-- 
    <div class="row">
        <a [routerLink]="['add']" class="button"> Добавить </a>
    </div> -->

    <div *ngIf="pageState$ |async as pageState" class="table">

        <ng-container [ngSwitch]="pageState.state">
            <ng-container *ngSwitchCase="StateValue.Success">
                <div class="table-head">
                    <div class="cell">Код</div>
                    <div class="cell">Тема</div>
                    <div class="cell">Статус</div>
                </div>
                <div class="table-body">
                    <div *ngFor="let item of pageState.data.items"
                        [ngClass]="{'col': true, 'active': activatedRowId === item.pageId}">

                        <div class="table-body-row" (click)="toggleRow(item.pageId)">
                            <div class="cell"> {{item.code}} </div>
                            <div class="cell"> {{item.subject}} </div>
                            <div [ngClass]="{'cell': true, 'inactive': !item.isActive}">
                                {{item.isActive ? 'Активно' : 'Приостановлено'}}
                            </div>
                        </div>

                        <i-collapsible [expanded]="activatedRowId === item.pageId">
                            <app-content-item-children *ngIf="activatedRowId === item.pageId"
                                [childrenStoreFunction]="getChildrenApi" [parentCode]="item.code">
                            </app-content-item-children>
                        </i-collapsible>

                    </div>
                </div>

            </ng-container>

            <div class="center" *ngSwitchCase="StateValue.InProgress">
                <i-in-progress size="40px"></i-in-progress>
            </div>

            <app-state-message [state]="pageState" *ngSwitchCase="StateValue.Error"> </app-state-message>

        </ng-container>

        <linde-pagination [itemsQty]="pageState.data?.count" (onPageChanged)="onPageChanged($event)"></linde-pagination>

    </div>

</div>