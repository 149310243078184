import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform {

    transform(value: string, search: string, inject: string = ''): string {
        if (value !== undefined && value !== null) {
            return value.split(search).join(inject);
            // return value.replace(search, inject);
        } else {
            return "";
        }
    }
}