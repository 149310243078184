import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, catchError, combineLatest, concat, debounceTime, distinctUntilChanged, map, Observable, of, share, switchMap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminUsersListResponseModel } from 'src/models/adminUsersListResponseModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { PaginationValue } from 'src/modules/shared/components/pagination/pagination.component';
import { AdminUsersApiService } from 'src/services/api/admin-users.api.service';
import { UiService } from 'src/services/ui.service';
import { Dropdowns } from 'src/modules/shared/helpers/dropdowns';

@Component({
  selector: 'linde-control-users',
  templateUrl: './control-users.component.html',
  styleUrls: ['./control-users.component.scss'],
  animations: [titleAnimation],
})
export class ControlUsersComponent {
  title = 'Список пользователей';
  Dropdowns = Dropdowns.userList;
  StateValue = StateValue;

  private _ordering$ = new BehaviorSubject<string>(this.Dropdowns.sort[0].id);
  private _filteringIsActive$ = new BehaviorSubject<boolean>(!!this.Dropdowns.filter[0].id);
  private _pagination$ = new BehaviorSubject<PaginationValue>({ itemsPerPage: 20, page: 1 });
  private _search$ = new BehaviorSubject<string>('');

  private _listApi$ =
    (ordering: string, filterIsActive: boolean, pagination: PaginationValue, searchValue: string) =>
      this.adminUsersApi.list({
        sortType: ordering,
        search: searchValue,
        showOnlyActive: filterIsActive,
        limit: pagination.itemsPerPage,
        offset: (pagination.page - 1) * pagination.itemsPerPage,
      })
        .pipe(
          map(list => ({ state: StateValue.Success, data: list } as ComponentState<AdminUsersListResponseModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message }))
        );

  constructor(
    private ui: UiService,
    private titleService: Title,
    private adminUsersApi: AdminUsersApiService
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  pageState$: Observable<ComponentState<AdminUsersListResponseModel>> = combineLatest([
    this._ordering$,
    this._filteringIsActive$,
    this._pagination$,
    this._search$.pipe(
      debounceTime(500),
      map(value => value?.length >= 3 ? value : ''),
      distinctUntilChanged(),
    ),

  ])
    .pipe(
      switchMap(_combined => concat(
        of({ state: StateValue.InProgress }),
        this._listApi$(..._combined),
      )),
      share(),
    );

  orderBy(e: Event) {
    this._ordering$.next((e.target as HTMLSelectElement).value);
    this.onPageChanged({ ...this._pagination$.value, page: 1 });
  }

  onPageChanged(pv: PaginationValue) {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    this._pagination$.next(pv);
  }

  search(e: Event) {
    this._search$.next((e.target as HTMLInputElement).value?.trim());
  }

  filterOnlyActive(e: Event) {
    this.onPageChanged({ ...this._pagination$.value, page: 1 });
    this._filteringIsActive$.next(!!+(e.target as HTMLSelectElement).value);
  }

}
