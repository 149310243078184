<div class="wrap center">
  <div class="container row">
    <a [routerLink]="['/']">
      <img src="/assets/icons/logo.png" alt="LOGO">
    </a>

    <div *ngIf="authorized" class="logout col" (click)="logout()">
      <img src="/assets/icons/login.svg" alt="LOGOUT">
      <p>Выход</p>
    </div>
  </div>
</div>