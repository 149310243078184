import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UiService } from 'src/services/ui.service';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminUsersApiService } from 'src/services/api/admin-users.api.service';
import { AdminUserModel } from 'src/models/adminUserModel';
import { AdminOrdersApiService } from 'src/services/api/admin-orders.api.service';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { BehaviorSubject, catchError, combineLatest, concat, map, merge, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { PaginationValue } from 'src/modules/shared/components/pagination/pagination.component';
import { AdminOrdersListResponseModel } from 'src/models/adminOrdersListResponseModel';
import { AdminUsersEditRequestModel } from 'src/models/adminUsersEditRequestModel';
import { Role, RoleList } from 'src/modules/shared/helpers/roles';
import { encryptPassword } from 'src/modules/shared/helpers/encrypt-password';


@Component({
  selector: 'linde-control-users-edit',
  templateUrl: './control-users-edit.component.html',
  styleUrls: ['./control-users-edit.component.scss'],
  animations: [titleAnimation]
})
export class ControlUsersEditComponent {
  title = 'Список пользователей / ';
  form: UntypedFormGroup;

  activeItemId: string;
  roles: Role[] = RoleList;
  StateValue = StateValue;
  isChangePassword = false;

  private _filteringIsActive$ = new BehaviorSubject<boolean>(false);
  private _pagination$ = new BehaviorSubject<PaginationValue>({ itemsPerPage: 20, page: 1 });
  private _submitForm$ = new Subject<AdminUsersEditRequestModel>();
  private _customerId$ = new Subject<string>();

  private _ordersApi$ =
    (filterIsActive: boolean, pagination: PaginationValue, customerId: string): Observable<ComponentState<AdminOrdersListResponseModel>> =>
      this.adminOrdersApi.list({
        showOnlyActive: filterIsActive,
        customerId,
        limit: pagination.itemsPerPage,
        offset: (pagination.page - 1) * pagination.itemsPerPage,
      })
        .pipe(
          map(list => ({ state: StateValue.Success, data: list } as ComponentState<AdminOrdersListResponseModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message }))
        );

  private _getUserApi$ =
    (userId: number): Observable<ComponentState<AdminUserModel>> =>
      this.adminUsersApi.get({ userId })
        .pipe(
          map(item => {
            this.ui.setTitle(this.title + item.name);
            this.titleService.setTitle(this.title + item.name);
            this.generateForm(item);
            return ({ state: StateValue.Success, data: item } as ComponentState<AdminUserModel>);
          }),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
        );

  private _updateUserApi$ = (model: AdminUsersEditRequestModel) => {
    delete model['passwordConfirm'];
    return this.adminUsersApi.edit({ ...model, password: encryptPassword(model.password) })
      .pipe(
        map(() => ({ state: StateValue.Success, msg: 'Пользователь успешно обновлен.' } as ComponentState)),
        catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
      );
  }

  constructor(
    private ui: UiService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private adminUsersApi: AdminUsersApiService,
    private adminOrdersApi: AdminOrdersApiService,
  ) {
    this.ui.setTitle(this.title + '...');
  }

  ngOnInit(): void {
    this.generateForm();
  }

  private generateForm(item?: AdminUserModel) {
    this.form = new UntypedFormGroup({
      userId: new UntypedFormControl(item?.userId, [Validators.required]),
      name: new UntypedFormControl(item?.name, [Validators.required]),
      phone: new UntypedFormControl(item?.phone, [Validators.required]),
      email: new UntypedFormControl(item?.email, [Validators.required]),
      roleId: new UntypedFormControl(item?.roleId, [Validators.required]),
      isActive: new UntypedFormControl(item?.isActive, [Validators.required]),
      isChangePassword: new UntypedFormControl(this.isChangePassword),
      password: new UntypedFormControl(''),
      passwordConfirm: new UntypedFormControl(''),
    });
  }

  isActive$: Observable<boolean> = this._filteringIsActive$;


  ordersState$: Observable<ComponentState<AdminOrdersListResponseModel>> = combineLatest([
    this._filteringIsActive$,
    this._pagination$,
    this._customerId$,
  ])
    .pipe(
      switchMap(_combined => concat(
        of({ state: StateValue.InProgress }),
        this._ordersApi$(..._combined),
      )),
      shareReplay(1),
    );

  updateUserState$: Observable<ComponentState> = concat(
    of({ state: StateValue.Initial }),
    this._submitForm$
      .pipe(
        switchMap(
          model => concat(
            of({ state: StateValue.InProgress }),
            this._updateUserApi$(model),
          )
        ),
      ),
  )
    .pipe(shareReplay(1));

  userState$: Observable<ComponentState<AdminUserModel>> = merge(
    of(null),
    this.updateUserState$,
  )
    .pipe(
      switchMap(() => this.activatedRoute.params),
      switchMap(
        _params => merge(
          of({ state: StateValue.InProgress }),
          this._getUserApi$(_params['id']).pipe(tap(currentState => {
            if (currentState.state === StateValue.Success) {
              this._customerId$.next(currentState.data.customerId);
            }
          }))
        )
      ),
      shareReplay(1),
    );

  filterOnlyActive(value: boolean) {
    this._filteringIsActive$.next(value);
  }

  onPageChanged(pv: PaginationValue) {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    this._pagination$.next(pv);
  }

  toggle(id: string) {
    this.activeItemId = id === this.activeItemId ? null : id;
  }

  submit() {
    if (this.form.value.password === this.form.value.passwordConfirm) {
      this._submitForm$.next({ ...this.form.value });
    }
  }
}

export interface EditUserPageViewModel {
  user: AdminUserModel;
  ordersResponse: AdminOrdersListResponseModel;
}
