import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  // authRegVisible$ = new BehaviorSubject<boolean>(false);
  menuBlockVisible$ = new BehaviorSubject<boolean>(true);
  cabTitle$ = new BehaviorSubject<string>('');
  error$ = new BehaviorSubject<string>(null);

  constructor() { }

  showMenuBlock() {
    this.menuBlockVisible$.next(true);
  }

  hideMenuBlock() {
    this.menuBlockVisible$.next(false);
  }

  setTitle(title: string) {
    this.cabTitle$.next(title);
  }

  // ERROR
  showError(text: string) {
    this.error$.next(null);
    this.error$.next(text);
  }

  hideError() {
    this.error$.next(null);
  }

  // HANDLE ERROR
  handleError = (err: HttpErrorResponse) => {
    this.showError(err.status >= 400 && err.status < 500
      ? err.error?.message ?? 'Что-то не то'
      : 'Что-то пошло не так');
    setTimeout(() => {
      this.hideError();
    }, 3000);
  }
}
