import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, map, merge, Observable, of, share, shareReplay, Subject, switchMap } from 'rxjs';
import { AuthAccountLoginRequestModel } from 'src/models/authAccountLoginRequestModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { encryptPassword } from 'src/modules/shared/helpers/encrypt-password';
import { AuthApiService } from 'src/services/api/auth.api.service';
import { AuthService } from 'src/services/auth.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-enter',
  templateUrl: './enter.component.html',
  styleUrls: ['./enter.component.scss']
})
export class EnterComponent implements OnInit {
  private _submit$ = new Subject<boolean>();
  StateValue = StateValue;
  form: UntypedFormGroup = new UntypedFormGroup({
    login: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private authApi: AuthApiService,
    private authService: AuthService,
    private ui: UiService,
    private router: Router,
  ) { }

  formState$: Observable<ComponentState> = merge(
    of({ state: StateValue.Initial }),
    this._submit$.pipe(
      switchMap(
        () => merge(
          of({ state: StateValue.InProgress }),
          this.authApi.login({ ...this.form.value, password: encryptPassword(this.form.value.password) })
            .pipe(
              map(_item => {
                this.authService.login(_item.token);
                this.router.navigate(['/']);
                return { state: StateValue.Success };
              }),
              catchError(err => of({ state: StateValue.Success, msg: err.error.message })),
            ),
        ),
      ),
    )
  ).pipe(shareReplay(1));

  ngOnInit(): void {
    if (!!this.authService.TOKEN)
      this.router.navigate(['/']);
  }

  submit() {
    this._submit$.next(true);
  }

}
