import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { MediaModel } from 'src/models/mediaModel';
import { AdminMediaApiService } from 'src/services/api/admin-media.api.service';
import { ComponentState, StateValue } from '../../helpers/component-state.interface';

@Component({
  selector: 'file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent {
  StateValue = StateValue;
  @Input() fileApi: AdminMediaApiService;
  @Output() onImagesLoaded = new EventEmitter<MediaModel>();
  @ViewChild('file') fileInputElem: ElementRef<HTMLInputElement>;

  private _fileChanged$ = new Subject<File>();

  private _fileApi$ =
    (file: File): Observable<ComponentState<MediaModel>> =>
      this.fileApi.create(file)
        .pipe(
          map(model => ({ state: StateValue.Success, data: model } as ComponentState<MediaModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message ?? 'Не удалось загрузить файл' }))
        );

  constructor() { }

  uploadedFileState$: Observable<ComponentState<MediaModel>> = concat(
    of({ state: StateValue.Initial }),
    this._fileChanged$
      .pipe(
        switchMap(file => concat(
          of({ state: StateValue.InProgress }),
          this._fileApi$(file),
        )),
        tap((currentState: ComponentState<MediaModel>) => {
          if (currentState.state === StateValue.Success)
            this.onImagesLoaded.emit(currentState.data);
        }),
      ),
  );

  clickFileInput() {
    this.fileInputElem.nativeElement.click();
  }

  onFileChanged(event: Event) {
    this._fileChanged$.next((event.target as HTMLInputElement).files.item(0));
  }

}
