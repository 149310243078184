import { Component, Input, OnInit } from '@angular/core';
import { concat, Observable, of } from 'rxjs';
import { AdminContentPagesListResponseModel } from 'src/models/adminContentPagesListResponseModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';

@Component({
  selector: 'app-content-item-children',
  templateUrl: './content-item-children.component.html',
  styleUrls: ['./content-item-children.component.scss']
})
export class ContentItemChildrenComponent implements OnInit {
  StateValue = StateValue;
  @Input() parentCode: string;
  @Input() childrenStoreFunction: (parentCode: string) => Observable<ComponentState<AdminContentPagesListResponseModel>>

  childrenState$: Observable<ComponentState<AdminContentPagesListResponseModel>>;
  constructor() { }

  ngOnInit(): void {
    this.childrenState$ = concat(
      of({ state: StateValue.InProgress }),
      this.childrenStoreFunction(this.parentCode),
    );
  }

}
