import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ControlCategoriesItemComponent } from './pages/control-categories-item/control-categories-item.component';
import { ControlCategoriesComponent } from './pages/control-categories/control-categories.component';
import { ControlContentAddComponent } from './pages/control-content-add/control-content-add.component';
import { ControlContentEditComponent } from './pages/control-content-edit/control-content-edit.component';
import { ControlContentComponent } from './pages/control-content/control-content.component';
import { ControlContractsComponent } from './pages/control-contracts/control-contracts.component';
import { ControlDataExchangeLogItemComponent } from './pages/control-data-exchange-log-item/control-data-exchange-log-item.component';
import { ControlDataExchangeLogComponent } from './pages/control-data-exchange-log/control-data-exchange-log.component';
import { ControlDocsComponent } from './pages/control-docs/control-docs.component';
import { ControlEmailsEditComponent } from './pages/control-emails-edit/control-emails-edit.component';
import { ControlEmailsComponent } from './pages/control-emails/control-emails.component';
import { ControlFeedbackItemComponent } from './pages/control-feedback-item/control-feedback-item.component';
import { ControlFeedbackComponent } from './pages/control-feedback/control-feedback.component';
import { ControlGoodsItemComponent } from './pages/control-goods-item/control-goods-item.component';
import { ControlGoodsComponent } from './pages/control-goods/control-goods.component';
import { ControlNotificationsAddComponent } from './pages/control-notifications-add/control-notifications-add.component';
import { ControlNotificationsEditComponent } from './pages/control-notifications-edit/control-notifications-edit.component';
import { ControlNotificationsComponent } from './pages/control-notifications/control-notifications.component';
import { ControlOrdersComponent } from './pages/control-orders/control-orders.component';
import { ControlUsersAddComponent } from './pages/control-users-add/control-users-add.component';
import { ControlUsersEditComponent } from './pages/control-users-edit/control-users-edit.component';
import { ControlUsersComponent } from './pages/control-users/control-users.component';
import { EnterComponent } from './pages/enter/enter.component';
import { CabGuard } from 'src/services/cab.guard';


const routes: Routes = [

    { path: 'enter', component: EnterComponent },
    // CONTROL
    {
        path: '',
        canActivate: [CabGuard],
        children: [
            { path: '', redirectTo: 'goods/catalog', pathMatch: 'full' },
            { path: 'goods', redirectTo: 'goods/catalog', pathMatch: 'full' },
            { path: 'goods/catalog', component: ControlGoodsComponent },
            { path: 'goods/catalog/:id', component: ControlGoodsItemComponent },
            { path: 'goods/categories', component: ControlCategoriesComponent },
            { path: 'goods/categories/:id', component: ControlCategoriesItemComponent },
            { path: 'goods/contracts', component: ControlContractsComponent },
            { path: 'users', component: ControlUsersComponent, pathMatch: 'full' },
            { path: 'users/add', component: ControlUsersAddComponent, pathMatch: 'full' },
            { path: 'users/:id', component: ControlUsersEditComponent },
            { path: 'orders', component: ControlOrdersComponent, pathMatch: 'full' },
            { path: 'notifications', component: ControlNotificationsComponent, pathMatch: 'full' },
            { path: 'notifications/add', component: ControlNotificationsAddComponent },
            { path: 'notifications/:id', component: ControlNotificationsEditComponent },
            { path: 'feedback', component: ControlFeedbackComponent },
            { path: 'feedback/:id', component: ControlFeedbackItemComponent },
            { path: 'settings/docs', component: ControlDocsComponent },
            { path: 'data-exchange-log', component: ControlDataExchangeLogComponent, pathMatch: 'full' },
            { path: 'data-exchange-log/:id', component: ControlDataExchangeLogItemComponent },
            { path: 'content', component: ControlContentComponent, pathMatch: 'full' },
            { path: 'content/add', component: ControlContentAddComponent, pathMatch: 'full' },
            { path: 'content/:id', component: ControlContentEditComponent },
            { path: 'emails', component: ControlEmailsComponent, pathMatch: 'full' },
            { path: 'emails/:id', component: ControlEmailsEditComponent },
        ],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
