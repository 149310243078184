<form *ngIf="form && formState" [formGroup]="form" class="col" (ngSubmit)="submit()">

    <div class="row dates">
        <i-form-item [control]="form.get('dateFrom')">
            <label> С </label>
            <input type="date" formControlName="dateFrom">
        </i-form-item>

        <i-form-item [control]="form.get('dateTo')">
            <label> По </label>
            <input type="date" formControlName="dateTo">
        </i-form-item>
    </div>

    <i-form-item *ngIf="isClients" [control]="form.get('customer')">
        <label> Клиент </label>
        <autocomplete formControlName="customer"></autocomplete>
    </i-form-item>

    <i-form-item [control]="form.get('subject')">
        <label> Тема </label>
        <input type="text" formControlName="subject">
    </i-form-item>

    <i-form-item [control]="form.get('text')">
        <label> Текст </label>
        <textarea formControlName="text" rows="10"></textarea>
    </i-form-item>

    <i-form-item [control]="form.get('priority')">
        <label> Приоритет </label>
        <select formControlName="priority">
            <option [value]="0"> Низкий </option>
            <option [value]="1"> Средний </option>
            <option [value]="2"> Высокий </option>
        </select>
    </i-form-item>

    <i-form-item [control]="form.get('isActive')">
        <div class="chb row">
            <input type="checkbox" formControlName="isActive" id="chb_is-active">
            <label for="chb_is-active"> активно </label>
        </div>
    </i-form-item>

    <app-state-message [state]="formState"></app-state-message>

    <button type="submit" [disabled]="form.invalid || formState.state === StateValue.InProgress">
        <ng-container *ngIf="formState.state !== StateValue.InProgress; else inProgress">
            Сохранить
        </ng-container>
        <ng-template #inProgress>
            <i-in-progress *ngIf="formState.state === StateValue.InProgress" size="24px"> </i-in-progress>
        </ng-template>
    </button>

</form>