import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, concat, map, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { AdminContentPageModel } from 'src/models/adminContentPageModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { ContentPageCode } from 'src/modules/shared/helpers/content-page-codes';
import { ContentPageTitle } from 'src/modules/shared/helpers/content-page-titles';
import { AdminContentApiService } from 'src/services/api/admin-content.api.service';
import { AdminMediaApiService } from 'src/services/api/admin-media.api.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-control-content-add',
  templateUrl: './control-content-add.component.html',
  styleUrls: ['./control-content-add.component.scss']
})
export class ControlContentAddComponent {
  title = `${ContentPageTitle[this.activatedRoute.snapshot.queryParams['parentCode']]}: Создание`;

  private _submit$ = new Subject<AdminContentPageModel>();
  private _createPage$ = (model: AdminContentPageModel) => this.adminContentApiService.create(model)
    .pipe(
      map(() => ({ state: StateValue.Success, msg: 'Сохранено успешно' } as ComponentState)),
      catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
    );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ui: UiService,
    private titleService: Title,
    private adminContentApiService: AdminContentApiService,
    public fileApi: AdminMediaApiService,
  ) {
    this.ui.setTitle(this.title);
    this.titleService.setTitle(this.title);
  }

  formState$: Observable<ComponentState> = concat(
    of({ state: StateValue.Initial }),
    this._submit$
      .pipe(
        switchMap(
          model => concat(
            of({ state: StateValue.InProgress }),
            this._createPage$(model),
          )
        )
      ),
  )
    .pipe(
      tap((currentState: ComponentState) => {
        if (currentState.state === StateValue.Success)
          this.router.navigate(['/content']);
      }),
      shareReplay(1),
    );

  pageCode$: Observable<ContentPageCode> = this.activatedRoute.queryParams
    .pipe(map(params => params['parentCode']));

  submit(model: AdminContentPageModel) {
    this._submit$.next(model);
  }

}
