<div class="wrap col" @titleAnimation>
  <div class="filters row">
    <input type="text" role="search" tabindex="0" class="search" placeholder="Поиск" (input)="search($event)">

    <select (change)="orderBy($event)">
      <option *ngFor="let o of Dropdowns.sort" [value]="o.id"> {{o.name}} </option>
    </select>
  </div>

  <div *ngIf="pageState$ |async as pageState" class="list">
    <ng-container [ngSwitch]="pageState.state">
      <ng-container *ngSwitchCase="StateValue.Success">

        <a [routerLink]="[item.productCategoryId]" *ngFor="let item of pageState.data.items" class="item row">
          <img [src]="item.media?.[0]?.photo640 ?? item.media?.[0]?.photoOriginal |fakeImg" alt="GAS">
          <div class="name"> {{item.name}} </div>
        </a>

        <div *ngIf="pageState.data?.items?.length === 0" class="msg warning">
          Список пуст
        </div>

      </ng-container>

      <app-state-message [state]="pageState" *ngSwitchCase="StateValue.Error"> </app-state-message>
      <div class="center" *ngSwitchCase="StateValue.InProgress">
        <i-in-progress size="40px"></i-in-progress>
      </div>

    </ng-container>

    <linde-pagination [itemsQty]="pageState.data?.count" (onPageChanged)="onPageChanged($event)"></linde-pagination>

  </div>

</div>