import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, concat, map, Observable, of, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminContentPageModel } from 'src/models/adminContentPageModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { AdminContentApiService } from 'src/services/api/admin-content.api.service';
import { AdminMediaApiService } from 'src/services/api/admin-media.api.service';
import { UiService } from 'src/services/ui.service';

@Component({
  selector: 'app-control-content-edit',
  templateUrl: './control-content-edit.component.html',
  styleUrls: ['./control-content-edit.component.scss'],
  animations: [titleAnimation],
})
export class ControlContentEditComponent {
  StateValue = StateValue;
  // title = `${ContentPageTitle[this.activatedRoute.snapshot.queryParams['parentCode']]}: Создание`;

  private _submit$ = new Subject<AdminContentPageModel>();
  private _getPage$ =
    (pageId: number): Observable<ComponentState<AdminContentPageModel>> =>
      this.adminContentApiService.get({ pageId })
        .pipe(
          map(model => ({ state: StateValue.Success, data: model } as ComponentState<AdminContentPageModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
        );

  private _updatePage$ =
    (model: AdminContentPageModel) =>
      this.adminContentApiService.edit(model)
        .pipe(
          map(() => ({ state: StateValue.Success, msg: 'Сохранено успешно' } as ComponentState)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message })),
        );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ui: UiService,
    private titleService: Title,
    private adminContentApiService: AdminContentApiService,
    public fileApi: AdminMediaApiService,
  ) { }

  pageState$: Observable<ComponentState<AdminContentPageModel>> = this.activatedRoute.params
    .pipe(
      switchMap(params => concat(
        of({ state: StateValue.InProgress }),
        this._getPage$(+params['id']),
      )),
    );

  formState$: Observable<ComponentState> = concat(
    of({ state: StateValue.Initial }),
    this._submit$
      .pipe(
        switchMap(
          model => concat(
            of({ state: StateValue.InProgress }),
            this._updatePage$(model),
          )
        )
      ),
  )
    .pipe(
      tap((currentState: ComponentState) => {
        if (currentState.state === StateValue.Success)
          this.router.navigate(['/content']);
      }),
      shareReplay(1)
    );


  submit(model: AdminContentPageModel) {
    this._submit$.next(model);
  }

}
