import { ContentPageCode } from "./content-page-codes";

export const ContentPageTitle: { [key: string]: string } = {
    [ContentPageCode.Banners]: 'Баннеры',
    [ContentPageCode.Benefits]: 'Преимущества',
    [ContentPageCode.Categories]: 'Категории',
    [ContentPageCode.Faq]: 'FAQ',
    [ContentPageCode.News]: 'Новости',
    [ContentPageCode.Pages]: 'Страницы',

    [ContentPageCode.Contacts]: 'Контакты',
    [ContentPageCode.Documents]: 'Документы on - line',
    [ContentPageCode.HowOrder]: 'Как заказать',
    [ContentPageCode.Delivery]: 'Способ доставки',
    [ContentPageCode.Privacy]: 'Политика конфиденциальности',
    [ContentPageCode.Company]: 'О компании Linde',
    [ContentPageCode.TermsOfUse]: 'Условия использования',
    [ContentPageCode.LegalInfo]: 'Юридическая информация',
    [ContentPageCode.Payment]: 'Оплата',
}