import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { catchError, concat, map, Observable, of, switchMap, tap } from 'rxjs';
import { titleAnimation } from 'src/animations/title.animation';
import { AdminExchangeMessageModel } from 'src/models/adminExchangeMessageModel';
import { ComponentState, StateValue } from 'src/modules/shared/helpers/component-state.interface';
import { AdminExchangeApiService } from 'src/services/api/admin-exchange.api.service';

@Component({
  selector: 'app-control-data-exchange-log-item',
  templateUrl: './control-data-exchange-log-item.component.html',
  styleUrls: ['./control-data-exchange-log-item.component.scss'],
  animations: [titleAnimation]
})
export class ControlDataExchangeLogItemComponent {
  title = 'Журнал обмена данными';
  StateValue = StateValue;
  descriptionShown: boolean = false;

  private _messageApi$ =
    (exchangeMessageId: string) =>
      this.adminExchangeApi.getMessage({ exchangeMessageId, includeMessageText: false })
        .pipe(
          map(item => ({ state: StateValue.Success, data: item } as ComponentState<AdminExchangeMessageModel>)),
          catchError(err => of({ state: StateValue.Error, msg: err?.error?.message }))
        );

  private _descriptionApi$ =
    (exchangeMessageId: string) =>
      this.adminExchangeApi.getMessage({ exchangeMessageId, includeMessageText: true })
        .pipe(
          map(item => ({ state: StateValue.Success, data: item.messageText ? JSON.parse(item.messageText) : null } as ComponentState<any>)),
          catchError(err => {
            return of({ state: StateValue.Error, msg: err?.error?.message ?? 'qweqwe' });
          })
        );

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private adminExchangeApi: AdminExchangeApiService,
  ) {
    this.titleService.setTitle(this.title);
  }

  briefMessageState$: Observable<ComponentState<AdminExchangeMessageModel>> = concat(
    of({ state: StateValue.InProgress }),
    this.activatedRoute.params
      .pipe(
        switchMap(
          params => this._messageApi$(params['id']),
        )
      ),
  );

  messageDescriptionState$: Observable<ComponentState<string>> = concat(
    of({ state: StateValue.InProgress }),
    this.activatedRoute.params
      .pipe(
        switchMap(params =>
          this._descriptionApi$(params['id']),
        )
      ),
  );
}
